<style lang="less">
.l-calender{
  color:var(--text2);
  height:100%;
  padding:0px;
}

.ugo-droppable{
  border:2px solid var(--active) !important;
}

</style>
<template>
<div class="l-calender">
  <div class="flex-wrap" style="width:100%;height:30px;font-weight:bold;">
      <BaseIcon icon="md-apps" style="margin-right:5px" />我的日历 </div>
    <div style="height:80px;margin-bottom:20px;" class="flex-wrap align-strech flex-between" v-if="!hideDate && !data.hideDate">
      <div class="flex-wrap flex-center m-light" style="flex-grow:1;height:100%;border-radius:10px;color:var(--text2);font-weight:bold;box-shadow:1px 1px 2px 1px var(--border);" :style="`font-size:180%`">
        <div>
          <div style="font-size:60%;opacity:0.8;"><span v-if="w>500">{{ moment().format('LL') }}</span> {{ lunaToday.lunarYear }}</div>
        <div style="font-size:1.4rem">农历 <span style="color:var(--active)">{{ lunaToday.lunar }}</span> <span style="font-size:18px;" v-if="lunaToday.holiday">({{ lunaToday.holiday }})</span></div>
      </div>
      </div>
      <div style="margin-left:10px;width:200px;position:relative;">
        <BaseClock  style="width:100%;height:80px;" />
      </div>
    </div>
    <div :style="`height:calc(100% - ${!hideDate &&  !data.hideDate?130:30}px);padding:5px;background:var(--bg3);border-radius:3px;border:2px solid var(--border);`">
    <template v-for="week in weeks">
      <div :key="'w'+week.id" class="flex-wrap" style="position:relative;height:calc(100% / 6);flex-wrap:nowrap;">
       
        <template v-for="d in week.days">
          <div :key="'d'+d.id" class="l-day" @click="select(d.id)"
          :data-id="d.id"
          v-droppable="handleDropDay"
          :class="{
            'l-day-out':d.out,
            'm-light':!d.out,
            'l-day-selected':selected.id==d.id,
            'l-day-today':d.id==today.format('YYYYMMDD')
          }">
            <span class="l-month" :style="d.name=='01'?'color:var(--primary);font-weight:bold;font-size:0.8rem;':'font-size:0.7rem;'">{{d.name == '01'?d.monthText:d.name}}</span>

        <template>
            <div class="l-day-type" :style="d.dayType!=='班'?`color:var(--success);filter:contrast(1.5);`:''">
              {{d.dayType || '班'}}
            </div>
            <div class="l-festival-type" :style="d.festival">
              {{d.festival}}
            </div>
        </template>
            <div class="l-day-events" v-if="d.memo">
              <template v-for="ev in d.events" >
                <div class="l-day-event" :key="ev.id" :style="`background:${(ev.finished?('var(--success)'):((moment(ev.datekey,'YYYYMMDD').isBefore(moment().startOf('day'))?'var(--error)':'var(--primary)')))}`">
                  <BaseIcon :icon="ev.icon" :size="8" color="var(--hover-text)" />
                </div>
              </template>
              
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</div>
</template>

<script>
  import layoutMixin from "@/mixins/layout"
  import responseMixin from "@/mixins/responsable"
  import moment from 'moment'
  export default {
    panelInfo:{
      name:'日程表',
      minWidthSpan:2,
    },
    data(){
      return {
        formDataMemo:{},
        today:moment().startOf('day'),
        modalMemo:false,
        memos:[],
        tasks:[],
        events:[],
        days:[],
        weekTitles:"一二三四五六日",
        weeks:[],
        lunaToday:{},
        current_time:''
      }
    },
    mixins:[layoutMixin,responseMixin],
    watch:{
      $route:{
        handler(route){
          let key = route.query?route.query.day:undefined
          this.baseDay = moment(key,"YYYYMMDD")
          this.calcMonth(this.baseDay)
        },
        immediate:true,
        deep:true
      }
    },
    
    mounted(){
        let key = this.$route.query?this.$route.query.day:''
        this.baseDay = key?moment(key,"YYYYMMDD"):moment()
        this.calcMonth(this.baseDay)
        
        this.timerInt = setInterval(()=>{
          this.current_time = moment().format("HH:mm:ss")
        },1000)
    },
    props:{
      hideDate:{
        type:Boolean
      },
      data:{
        type:Object,
        default:()=>({})
      }
    },
    activated(){
      setTimeout(()=>{
          this.getData()
        },500)
    },
    beforeDestroy(){
      clearInterval(this.timerInt)
    },  
    computed:{
      selected(){
        let key = this.$route.query.day
        return {
          id:key
        }
      }
    },
    methods:{

      getData(){
        let daykey = moment().format("YYYYMMDD")
        this.loading = true
        this.$api.get('/queries/luna?date='+daykey).then(res=>{
          let data = res.data.data&&res.data.data.data?res.data.data.data:{}
         
          this.lunaToday = data
        }).catch(e=>{
          console.error(e)
        })
      },  
      Today(){
        this.select(moment().format("YYYYMMDD"))
      },
      nextMonth(){
        if(this.baseDay)
          this.baseDay = this.baseDay.add(1,"month")
        this.calcMonth(this.baseDay)
      },
      prevMonth(){
        if(this.baseDay)
          this.baseDay = this.baseDay.subtract(1,"month")
        this.calcMonth(this.baseDay)
      },
      getMemos(){
         if(this.loading)
          return
        this.loading = true
        setTimeout(()=>{
          
        this.$api.get(`/memos?q=month-count&key=${this.baseDay.format('YYYYMM')}`).then(res=>{
          let counts = res.data.data
          this.days.forEach(v=>{
            let memo = counts[v.id]
            
            if(memo){
              if(memo.count > 0){
                this.$set(v,'memo',memo.count)
                this.$set(v,'events',memo.events)
              }

              

              if(memo.finished == memo.count)
                this.$set(v,'finished',memo.finished)
            }

          })
          }).finally(()=>{
            this.loading = false
        })
        },500)
      },
      select(daykey){
        let path = "/core/tasks?day="+daykey
        this.$store.commit("session/push",{
          icon:"md-calendar",
          name:"计划管理",
          size:22,
          base_url:"/core/tasks",
          path
        })
       this.RouteTo(path)
      },
      handleDropDay(task_id,{id}){
        this.$emit('on-event',{type:'drop-in-day',value:{
          task_id,
          day_id:id
        }})
      },
      calcMonth(mDay){
        if(this._calced && this._calced.isSame(mDay))
          return
        this._calced = mDay.clone()

        let weeks = []
        let week = null
        let days = []
        let startOfDay = mDay.clone().startOf('month')
        let startOfSegment = startOfDay.clone().subtract(startOfDay.weekday(),'days')
        if(!startOfDay.isValid())
          return 
        let day;
        
        for(day = startOfSegment;day.isBefore(startOfDay);day = day.add(1,'day')){
          if(week == null){
            week = {
              id:day.format("WW"),
              days:[]
            }
          }
          let dayObject = {
            id:day.format("YYYYMMDD"),
            name:day.format("DD"),
            out:true,
            month:day.format('YYYYMM'),
            monthText:day.format('M月'),
            dayType:day.weekday()>4?'休':'班',
            weekday:'星期'+('一二三四五六日'[day.weekday()])
          }

          week.days.push(dayObject)
          days.push(dayObject)
          if(week.days.length == 7){
            weeks.push(week)
            week = null
          }
        }
        
        for(day = startOfDay.clone();day.isBefore(startOfDay.endOf('month'));day = day.add(1,'day')){
          if(week == null){
            week = {
              id:day.format("WW"),
              days:[]
            }
          }
          let dayObject = {
            id:day.format("YYYYMMDD"),
            month:day.format('YYYYMM'),
            monthText:day.format('M月'),
             dayType:day.weekday()>4?'休':'班',
            name:day.format("DD"),
            weekday:'星期'+('一二三四五六日'[day.weekday()])
          }
          week.days.push(dayObject)
             days.push(dayObject)
          if(week.days.length == 7){
            weeks.push(week)
            week = null
          }
        }

        for(;week && week.days && week.days.length !=0 || weeks.length < 6;day = day.add(1,'day')){
          if(week == null){
            week = {
              id:day.format("WW"),
              days:[]
            }
          }
          let dayObject = {
            id:day.format("YYYYMMDD"),
            name:day.format("DD"),
            monthText:day.format('M月'),
             dayType:day.weekday()>4?'休':'班',
            month:day.format('YYYYMM'),
            out:true,
            weekday:'星期'+('一二三四五六日'[day.weekday()])
          }
             days.push(dayObject)
          week.days.push(dayObject)
          if(week.days.length == 7){
            weeks.push(week)
            week = null
          }
        }
        this.days = days
        this.weeks = weeks
         this.getMemos()
      }
    }
  }
</script>

<style lang="less">
.l-calender{
  height:100%;
  width:100%;
  position: relative;

}
.l-day{
  height:(100% - 15px);
  background:var(--bg2);
  margin-right:8px;
  color:var(--text2);
  padding:3px 8px;
  position: relative;
  flex-shrink: 1;
  flex-grow:1;
  border-radius: 2px;
  width:calc(100% / 7 - 10px);
  
  border:1px solid var(--border);

}

.l-day:nth-child(7n+7){
  margin-right:0;
}

.l-day:active{
  position: relative;
  bottom:-1px;
  right:-1px;
}

.l-day-out{
  color:var(--subtext3);
  filter:brightness(0.86);
  background:var(--bg3);
  box-shadow: none;
}

.l-day-selected{
  border:1px solid var(--primary);
}

.l-day-head{
  height:30px;
  display:flex;
  align-items: center;
  justify-content: center;
  background:none;
  color:var(--bg1);
}

.l-year{
  font-size:10px;
  padding:0px 20px;
  
  padding-top:10px;
}

.l-date{
  
  font-size:60px;
  font-family: Impact;
  margin:0px 20px;
}

.l-day:hover{
  cursor: pointer;
  border:2px solid var(--primary);
}

.l-month{
    position: relative;
    left:-5px;
  }

.l-day-today{
  background:var(--primary);
  color:var(--hover-text);
  .l-month{
    color:var(--hover-text) !important;
  
  }
  .l-day-event{
    background-blend-mode: difference;
  }
  .l-day-type{
    color:var(--hover-text) !important;
    
  }
}

.l-date-detail{
  
  color:#aaa;
  padding:20px;
}

.l-mixin{
  padding:10px 20px;
  color:#333;
  left:20px;
  font-size:14px;
  overflow: hidden;

  .l-mixin-label{
    font-size:16px;
    color:#222;
  }

  .l-mixin-item{
    width:50px;
    font-size:12px;
  }
  
}


.l-day-type{
  position: absolute;
  right:5px;
  bottom:5px;
  font-size:11px;
  color:var(--subtext3);
}

.l-day-events{
  position: absolute;
  right:3px;
  height:8px;
  border-radius: 50%;
  top:3px;
  width:70%;
  display: flex;
  flex-wrap:wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-end;
  gap:2px;
 
}
.l-day-event{
  width:12px;
  border-radius: 2px;
  height:12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background:#222;
  opacity:0.8;
}


.l-festival-type{
  position: absolute;
  right:5px;
  top:10px;
}



</style>
