<template>
    <div class="container">
     
      <div class="flex-wrap split5" style="height:75px;background:var(--bg3);border-bottom:1px solid var(--border);padding:0 5px;">
        <div class="flex-col justify-center" style="height:55px;padding:2px 10px;background:var(--bg3);color:var(--text3);border-radius:5px;border:1px solid var(--border);cursor:pointer" :style="`${filter.mcharger_id?'border-color:var(--border)':'border-color:var(--primary);background:var(--border)'}`"  @click="delete filter.mcharger_id;getData()">
          <div style="height:17px;margin-top:3px;">
            所有项目 
          </div>
          <div style="font-size:20px;font-weight:bold;">
            {{ mtotal }}
          </div>
        </div>
        <template v-for="g in mgroups">
        <div class="flex-col justify-center" style="height:55px;padding:2px 10px;background:var(--bg3);cursor:pointer;color:var(--text3);border-radius:5px;border:1px solid var(--border)" :key="g.key" :style="`${filter.mcharger_id !== g.key?'border-color:var(--border)':'border-color:var(--primary);background:var(--border)'}`" @click="filter.mcharger_id=g.key;getData()">
          <div style="height:20px;">
            <BaseNameBoard :uid="g.key" hide-avatar empty-name="未分组" />
          </div>
          <div style="font-size:20px;font-weight:bold;">
            {{g.count}}
          </div>
        </div>
        
        </template>
      </div>
      <div class="flex-wrap tool-box" style="background:var(--bg3);padding:10px;">
        
        <Input 
          search 
          style="width:220px" 
          v-model="searchText" 
          clearable 
          placeholder="请输入项目名称查询" 
          @on-search="getData()"
          @on-enter="getData()"
        />
        
        <Select placeholder="业务类型" v-model="filter.type" multiple style="margin-left:10px;width:140px"  clearable @on-change="getGroups();getData()">
          <template v-for="(r,i) in business_types">
            <Option :value="r" :key="i">{{r}}</Option>
          </template>
        </Select>
         <Select placeholder="项目状态" v-model="filter.status"  style="margin-left:10px;width:auto"  clearable multiple @on-change="getGroups();getData()">
          <template v-for="(r,i) in status">
            <Option :value="r" :key="i">{{r}}</Option>
          </template>
        </Select>
        <Select placeholder="合同金额" v-model="filter.amount" multiple style="margin-left:10px;width:auto;"  clearable @on-change="getGroups();getData()">
          <template v-for="(r,i) in amount_regions">
            <Option :value="r.name" :key="i">{{r.name}}</Option>
          </template>
        </Select>
  
        <BaseUserSelectGrouped v-model="filter.charger_id" :included="charger_ids" style="width:100px;margin-left:10px;" @on-change="getData()" placeholder="项目负责人" />
        <Button style="margin-left:10px" @click="getGroups();getData()" :loading="loading">刷新</Button>
      </div>
      <div style="height:calc(100% - 125px);position:relative;padding:0 10px;background:var(--bg3);">
        <BaseTable 
          :columns="columns" 
          :loading="loading" 
          :data="items"  
          :page="page"
          :sum="sum"
          :page-size="pagesize"
          border
          @event="handleTableEvent" 
          :selectable="editing_collection?'multiple':'no'"
          :selection="selection"
          :count="total"
          :option="{
            summary:true
          }"
        />
      </div>
  
      <Modal v-model="modalFactorConfig" footer-hide fullscreen inner transfer class-name="app-modal">
        <div class="container">
          
        </div>
      </Modal>
  

          <Modal
              v-model="showModalCharger"
              title="修改负责人"
              width="300"
              footer-hide
              :mask-closable="false"
          >
              <div>
                  <Form>
                    <FormItem label="片区负责人">
                          <BaseUserSelectGrouped
                              v-model="current.mcharger_id"
                              editable
                              :option="{ users }"
                          />
                      </FormItem>
                      <FormItem label="项目负责人">
                          <BaseUserSelectGrouped
                              v-model="current.charger_id"
                              editable
                              :option="{ users }"
                          />
                      </FormItem>
                      <FormItem label="协助人">
                          <BaseUserSelectGrouped
                              v-model="current.assistants"
                              editable
                              :option="{ users, multiple: true }"
                          />
                      </FormItem>
                  </Form>
  
                  <div class="flex-wrap flex-right split10" style="margin-top: 10px">
                      <Button type="primary" @click="SaveCharger">提交</Button>
                      <Button @click="showModalCharger = false">取消</Button>
                  </div>
              </div>
          </Modal>
  
  
      <Modal v-model="showModalProgress" title="签约计划" width="1200" footer-hide>
        <BaseLoading v-if="loadingProgress"></BaseLoading>
        <div v-else class="flex-col align-start" >
          <h2>123{{current.name}}</h2>
          <ProjectProgress  :value="current" style="width:100%"  />
        </div>
      </Modal>
  
  
      <Modal v-model="showModalFocus" title="关注原因" width="600" footer-hide>
        <Input type="textarea" v-model="formData.focused_reason" clearable />
        <div class="flex-wrap flex-between" style="margin-top:10px">
          <div class="flex-wrap">
            级别
          <BaseMark v-model="formData.focused" style="margin-left:10px" editable></BaseMark>
          </div>
          
          <Button type="primary" @click="handlePatchFocused">提交</Button>
        </div>
      </Modal>

      <Drawer 
        v-model="showEditor" 
        draggable 
        :class-name="className" 
        ref="modal" 
        :title="model.id?'编辑项目':'新建项目'" :width="800" footer-hide>
        <FormCreateContract 
            ref='form_create'
            v-model="model"
            :submitting="submitting" 
            @on-submit="handleSubmit" 
            @on-error="handleModelError" 
            @on-cancel="showEditor=false" />
        
        </Drawer>

        <Modal title="设置项目年度目标" footer-hide v-model="showTargetEditor">
            <Form :model="formData">
                <div>
                    <BaseBoardSubTitle :name="`${moment().year()}年确保完成`"></BaseBoardSubTitle>
                   <div style="margin:5px;border:1px solid var(--border);padding:15px">
                    <FormItem label="完成收费">
                        <BaseAmountInput v-model="formData.target_yearbilled_1_2025" />
                    </FormItem>
                    <FormItem label="支付节点">
                        <Input  v-model="formData.target_currentnode_2_2025"></Input>
                    </FormItem>
                </div>
                </div>
                <div>
                    
                    <BaseBoardSubTitle :name="`${moment().year()}年争取完成`"></BaseBoardSubTitle>
                    <div style="margin:5px;border:1px solid var(--border);padding:15px">
                    <FormItem label="完成收费">
                        <BaseAmountInput  v-model="formData.target_yearbilled_3_2025" />
                    </FormItem>
                    <FormItem label="支付节点">
                        <Input  v-model="formData.target_currentnode_4_2025"></Input>
                        
                    </FormItem>
                    </div>
                </div>
                <div>
                    
                    <FormItem label="协助人">
                        <BaseUserSelect  v-model="formData.target_assistid_5_2025" />
                    </FormItem>
                </div>
                <div class="flex-wrap flex-center split5">
                    <Button type="primary" @click="submitTargetEditor">提交</Button>
                    <Button @click="showTargetEditor=false">取消</Button>
                </div>
            </Form>
        </Modal>

       <Modal v-model="showProgressPlan" title="进度总控计划" footer-hide :width="1200">
         <div>
            <BaseEmpty v-if="openning">
                <BaseLoading />
            </BaseEmpty>
            <div v-else>
                <ProgressPlan :value="progressPlan" />
            </div>
         </div>
       </Modal>

       <Modal v-model="showBillPlan" title="收费计划" footer-hide :width="1200">
         <div>
            <BaseEmpty v-if="openning">
                <BaseLoading />
            </BaseEmpty>
            <div v-else>
                <BillPlan :data="billPlan" />
            </div>
         </div>
       </Modal>
  
    </div>
  </template>
  
  <script>
  
  import { mapGetters } from 'vuex'
  import moment from 'moment'
  import ProjectProgress from "@/components/cm/Progress"
  import { intersection } from 'lodash'
  import UserCollection from '@/components/cm/base/UserCollection'
  import FormCreateContract from '@/components/forms/CreateContract'
  import ProgressPlan from '@/components/cm/Progress'
  import UTIL from '@/utils'
  import PageStateMixin from '@/mixins/page_state'
  import BillPlan from '@/components/cm/BillPlan'
  
  import CMR from '../render'
import { filter } from 'lodash'
import { _ } from 'core-js'
  export default {
    data() {
      return {
        items: [],
        loading: true,
        loadingProgress:false,
        modalFactorConfig:false,
        showModalFocus:false,
        className:'',
        current: {},
        searchText:"",
        mtotal:0,
        mgroups:[],
        filter:{
          
          status:["准备中","进行中"],
          amount:[]
        },
        status: ["准备中", "进行中", "已竣工", "已结束", "已中止"],
        showModalProgress:false,
        showModalFocus: false,
        zzl_search_text: "",
        sum:{},
        showContract: false,
        showModalTmpl: false,
        showModalDeps: false,
        showTargetEditor:false,
        charger_ids:[],
        page:1,
        pagesize:50,
        showModalAssistants: false,
        showModalCharger: false,
        status_colors:[],
        modified: null,
        showModalAdjust: false,
        total:0,
        order:{},
        formData: {},
        business_types: ['全过程咨询', '项目管理', '房建监理', '市政监理', '造价咨询', 'BIM咨询', '招标代理', '对外合作', '其他','装修工程'],
        business_type_colors: ['orange', '#369', 'green', 'darkred', 'purple', '#3af', 'grown', 'pink', '#333','#128'],
        scrollTop:0,
        // page-state-fields
        pageStateFields:['filter','page','pagesize','searchText','selectedCollection','scrollTop'],
        selected_collection:null,
        editing_collection:false,
        selection:[],
        model:{},
        submitting:false,
        showEditor:false,
        showProgressPlan:false,
        progressPlan:null,
        showBillPlan:false,
        billPlan:null,
        openning:false
      }
    },
    mixins:[PageStateMixin],
    mounted() {
        
      this.$store.dispatch("session/getUsers")
      this.$store.dispatch("session/getDeps")
    },
    components:{ProjectProgress,UserCollection,FormCreateContract,ProgressPlan,BillPlan},
    methods: {
      __beforeSavePageState(){
        let dom = this.$el.querySelector('.ivu-table-overflowY')
        if(dom)
          this.scrollTop = dom.scrollTop
      },
      __afterRestorePageState(){
        this.getGroups()
        this.getData(()=>{ 
          setTimeout(()=>{
            let dom = this.$el.querySelector('.ivu-table-overflowY')
            if(dom)
              dom.scrollTop = this.scrollTop
          },1000)  
        })
      },
      openProgress(id){
        this.openning = true
        this.$api.get(`cm/projects/${id}?q=adjust-plan`).then(res=>{
            this.progressPlan = res.data.data
            this.showProgressPlan = true
        }).catch(e=>{
            this.Error(e)    
        }).finally(()=>{
            this.openning = false
        })
      },
      openBillPlan(id){
        this.openning = true
        this.$api.get(`cm/projects/${id}?q=bill-plan`).then(res=>{
            this.billPlan = res.data.data
            this.showBillPlan = true
        }).catch(e=>{
            this.Error(e)    
        }).finally(()=>{
            this.openning = false
        })
      },
      handleSaveCollection(){
  
      },
      handleEditCollection(c){
        this.editing_collection=true;
        this.selection = c.items.map(v=>v.target_id)
      },

      handleEdit(contract_id){
        this.loading = true
        this.$api.get(`/cm/contracts/${contract_id}`).then(res=>{
          this.model = res.data.data
          this.showEditor = true
        }).finally(()=>{
          this.loading = false
        })
      },
      handleModelError(){
        this.className = 'animate__animated animate__headShake'
        setTimeout(()=>{
          this.className = ''
        },500)
      },
      handleSubmit(model){
        if(this.model.id){
            let shrinked_model = UTIL.compare_patch_object(this.model,model)
           
            this.submitting = true
            this.$api.patch("cm/projects/"+this.model.id,shrinked_model).then(res=>{
            this.getData()
            this.formData = {}
            this.showEditor = false
            this.$Notice.success({title:"操作成功",content:"项目已修改"})
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            this.submitting = false
          })
        }else{
           this.submitting = true
          this.$api.post("cm/projects",model).then(res=>{
            this.getData()
            this.showEditor = false
            this.formData = {}
            this.$Notice.success({title:"操作成功",content:"项目已创建"})
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            this.submitting = false
          })
        }
      },

      getData(cb_success) {
        this.loading = true
        const year = moment().year()
        let option = {
            page:this.page,
            search:this.searchText,
            pagesize:this.pagesize,
            order:this.order,
            filter:this.filter,
            role:this.my_role.id,
            fields:['id','type','shortname','signed_amount','mcharger_id','charger_id','assistants','updated_at','origin_plan','current_plan','bill_plans','hr','code','name','amount','current_node_status','income_amount','status','signed_at','buildingArea','deduct_amount','adjust_amount','year_bill_plan_amount','last_year_end_billed','billed_amount','stype','last_bill','next_plan',"is_项目管理","is_监理","is_招标代理","is_造价咨询","is_BIM咨询",...this.target_keys,"focused_reason","focused","year_billed_amount","current_node",
            "target_assistid_5_2025"],
            sum:["amount","income_amount","signed_amount","deduct_amount","adjust_amount","year_bill_plan_amount","last_year_end_billed","billed_amount",`target_yearbilled_1_${year}`,`target_yearbilled_3_${year}`,"year_billed_amount"]
          }
          if(this.editing_collection || this.selected_collection){
            option.collection = this.selected_collection
          }
          this.$api.post('cm/projects/list',option,{timeout:40000}).then(res=>{
            let result = res.data.data
            this.items = result.items
            this.total = result.total.count
            if(!this.filter.charger_id)
              this.charger_ids = result.total.charger_ids
            this.sum = result.total.sum
            if(typeof cb_success == 'function')
              cb_success()
  
  
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            setTimeout(()=>{
              this.loading = false
            },200)
          })
      },
      handleTableEvent(e){
          if(e.type == 'page-change'){
            this.page = e.data
            this.getData()
          }else  if (e.type == 'open') {
        this.$store.commit("push_project_cm",e.data)
          this.RouteTo('/core/projects/' + e.data.id + '/cm')
        }else if(e.type == 'edit-name'){
            this.model = e.data
            this.formData = {shortname:e.data.shortname || ''}
            this.showShortNameEditor = true
          }else if(e.type == 'page-size-change'){
            this.pagesize = e.data
            this.getData()
          }else if(e.type == 'sort'){
            if(e.data && e.data.key){
              
              this.order = e.data
              this.page = 1
              this.getData()
            }else{
              this.order = {}
              this.getData()
            }
          }
       
      },
      handlePatchFocused() {
        this.$api.patch(`projects/${this.formData.id}`, {
          focused: this.formData.focused,
          focused_reason: this.formData.focused_reason
        }).then(res => {
          this.showModalFocus = false
  
          let updateInfo = res.data.data
          Object.assign(updateInfo,{
            id: this.formData.id,
            focused: this.formData.focused,
            focused_reason: this.formData.focused_reason
          })
          this.updateItem(updateInfo)
          this.formData = {}
          this.$Notice.success({ title: "修改成功" })
        })
      },
      updateItem(item) {
              let index = this.items.findIndex((v) => v.id == item.id);
              if (index != -1)
                  this.items.splice(index, 1, Object.assign(this.items[index], item));
              else this.items.splice(0, 0, item);
          },
      handlePatchAdjustAmount() {
        this.$api.patch(`projects/${this.formData.id}`, {
          adjust_amount: this.formData.adjust_amount
        }).then(res => {
          this.showModalAdjust = false
  
          let updateInfo = {
            id: this.formData.id,
            adjust_amount: this.formData.adjust_amount,
            amount: this.formData.signed_amount + this.formData.adjust_amount
          }
  
          this.$store.commit('cm/update_project', updateInfo)
          this.formData = {}
          this.$Notice.success({ title: "修改成功" })
        })
      },
      handleSelectFilter(f, v) {
        if (Array.isArray(this.selected_filters[f])) {
          let index = this.selected_filters[f].findIndex(t => t == v)
          if (index != -1)
            this.selected_filters[f].splice(index, 1)
          else
            this.selected_filters[f].push(v)
        } else
          this.$set(this.selected_filters, f, [v])
      },
      inArray(array, sub) {
        if (Array.isArray(array) && Array.isArray(sub) && array.length > 0 && sub.length > 0 && sub.length <= array.length) {
          for (let i = 0; i < sub.length; i++)
            if (!array.includes(sub[i]))
              return
          return true
        }
      },
      outArray(array, sub) {
        if (Array.isArray(array) && Array.isArray(sub) && array.length > 0 && sub.length > 0) {
          for (let i = 0; i < sub.length; i++)
            if (array.includes(sub[i]))
              return false
          return true
        }
      },
      handleOpen(e) {
        let project = this.items.find(v=>v.id == e.id)
        this.$store.commit("session/push_project_cm",project)
        this.RouteTo('/core/projects/' + e.id + '/cm')
      },
      handleClearGroup(g) {
        if (!g)
          this.selected_cols = []
        else
          this.selected_cols = this.selected_cols.filter(v => !g.items.map(t => t.key).includes(v))
      },
      handleSelectAllGroup(g) {
        g.items.forEach(v => {
          if (!this.selected_cols.includes(v.key))
            this.selected_cols.push(v.key)
        })
      },
      handleEditTarget(id){
        let item = this.items.find(p=>p.id == id)
        this.formData = _.pick(item,this.target_keys)
        this.formData.id = id
        this.showTargetEditor =  true
      },
      submitTargetEditor(){
        this.submitting = true
        let data = this.formData
        let id = data.id
        delete data.id
        this.$api.patch("cm/projects/"+id,data).then(res=>{
            this.getData()
            this.formData = {}
            this.showTargetEditor = false
            this.$Notice.success({title:"操作成功",content:"项目已修改"})
        }).catch(e=>{
            this.Error(e)
        }).finally(()=>{
            this.submitting = false
        })
      },
      handleSelectColumn(key) {
        let index = this.selected_cols.findIndex(v => v == key)
        if (index == -1)
          this.selected_cols.push(key)
        else
          this.selected_cols.splice(index, 1)
      },
      handleTableEvent(e) {
        if (e && e.type == 'open') {
          let baseurl = '/core/projects/' + e.data.id
          // this.$store.commit('session/pushProcess', { name: e.data.name, custom: 'jianzhu', url: baseurl + '/cm', baseurl })
          this.RouteTo(baseurl + '/cm')
  
        }
      },
      getGroups(){
        this.loading = true
        let filter = {...this.filter}
        delete filter.mcharger_id
        delete filter.charger_id
        let option = {
            filter,
            role:this.my_role.id,
            fields:[],
          }
          if(this.editing_collection || this.selected_collection){
            option.collection = this.selected_collection
          }
          
          this.$api.post('cm/projects/list',option,{timeout:40000}).then(res=>{
            let result = res.data.data
            this.mtotal = result.total.count
            this.mgroups = result.total.mgroups || []
            this.charger_ids = result.total.charger_ids
  
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            setTimeout(()=>{
              this.loading = false
            },200)
          })
      },
      EditCharger(item) {
        this.current = {
          id: item.id,
          charger_id: item.charger_id,
          assistants: item.assistants ? [...item.assistants] : []
        }
        this.showModalCharger = true
      },
      handlePatchFocused(){
        this.$api.patch(`projects/${this.formData.id}`, {
        focused: this.formData.focused,
        focused_reason: this.formData.focused_reason
      }).then(res => {
        this.showModalFocus = false

        let updateInfo = {
          id: this.formData.id,
          focused: this.formData.focused,
          focused_reason: this.formData.focused_reason
        }
        this.updateItem(updateInfo)

        this.formData = {}
        this.$Notice.success({ title: "修改成功" })
      })
      },
      SaveCharger() {
        let item = this.current
        this.$api.patch('projects/' + item.id, { charger_id: item.charger_id, assistants: item.assistants,mcharger_id:item.mcharger_id }).then(res => {
          this.$Notice.success({
            title: "修改成功"
          })
          let updateInfo = res.data.data
          updateInfo.id = item.id
          updateInfo.mcharger_id = item.mcharger_id
          updateInfo.charger_id = item.charger_id
          updateInfo.assistants = item.assistants
          let index = this.items.findIndex(v => v.id == item.id)
          if (index != -1)
            this.items.splice(index, 1, Object.assign(this.items[index], updateInfo))
          this.showModalCharger = false
          this.current = {}
        })
      },
      EditDeps(item) {
        this.showModalDeps = true
        this.current = {
          id: item.id,
          deps: item.deps
        }
      },
      SaveDeps() {
        let item = this.current
        this.$api.patch('projects/' + item.id, { deps: item.deps }).then(res => {
          this.$Notice.success({
            title: "修改成功"
          })
          let updateInfo = res.data.data
          Object.assign(item, updateInfo)
          this.$store.commit('cm/update_project', item)
          this.showModalDeps = false
          this.current = {}
        })
      },
    },
    computed: {
      ...mapGetters('session', ['session', 'users']),
      ...mapGetters('cm',['my_role']),
      target_keys(){
        let year = moment().year()
        return [`target_yearbilled_1_${year}`,`target_currentnode_2_${year}`,`target_yearbilled_3_${year}`,`target_currentnode_4_${year}`]
      },
      amount_regions(){
          return [{
            name:"100万元以下",
          },{
            name:"100万",
          },{
            name:"500万",
          },{
            name:"1000万",
          },{
            name:"2000万以上",
          }]
        },
      deps() {
        return this.$store.getters["session/deps"].filter(v=>v.in_business)
      },
      columns() {
        var that = this
        let year = moment().year()
        return [ {
          type: 'text',
          key: "code",
          title: "编号",
          fixed:"left",
          width: 70,
          group: '项目',
          option: {
  
            align: "center",
          }
        }, {
          type: 'text',
          title: "小微",
          key: "stype",
          fixed:"left",
          group: '项目',
          width: 60,
          option: {
  
            align: "center",
          },
          render: (h, { row }) => {
            if(row.amount < 1000000 || row.stype){
                return h('div', { class: "flex-wrap flex-center", style: { height: "40px" } }, [h('div', {            style: {              width: "40px",
                borderRadius: "5px",
                textAlign: "center",
                background:'var(--subtext2)',
                color: "#fff"
              }          }, "小微")])
            }else{
                return h('span','-')
            }
            
          }
        }, {
          type: 'text',
          title: "业务类型",
          fixed:"left",
          key: "type",
          group: '项目',
          width: 100,
          option: {
  
            align: "center",
          },
          render: (h, { row }) => {
            return h('div', { class: "flex-wrap flex-center", style: { height: "40px" } }, [h('div', {            style: {              width: "70px",
                borderRadius: "5px",
                textAlign: "center",
                background: this.business_type_colors[this.business_types.findIndex(v => v == row.type)],
                color: "#fff"
              }          }, row.type)])
          }
        }, {
          type: 'text',
          title: "名称",
          tree: true,
          fixed:"left",
          key: "name",
          minWidth: 240,
          render:CMR.renderName(this.handleOpen)
        }, {
                      type: "text",
                      title: "状态",
                      key: "status",
                      width: 80,
                      option: {
                          align: "center",
                      },
                      render: (h, { row }) => {
              
              const states = ["准备中", "进行中", "已竣工", "已结束", "已中止"]
              const status_colors = {
                "准备中":"#aaa",
                "进行中":"var(--primary)",
                "已竣工":"var(--active)",
                "已结束":"var(--success)",
                "已中止":"var(--error)"
              }
                let iconArrow = h("BaseIcon",{props:{icon:"ios-arrow-down"},style:"margin-left:3px;"})
                          let domContent = h(
                              "div",
                              { class: "flex-wrap flex-center", style: { height: "40px" } },
                              [
                                  h(
                                      "div",
                                      {
                                          style: {
                                              width: "50px",
                                              borderRadius: "50px",
                                              textAlign: "center",
                                              background:status_colors[row.status],
                                              color: "#fff",
                                          },
                                      },
                                      [row.status]
                                  ),
                              ]
                          );
  
                        
                          return domContent;
                      },
                  },	{
          type:"time",
          title:"签约时间",
           key: "started_at",
          width:100,
          render:(h,{row})=>{
            if(row.signed_at)
              return h("a",moment(row.signed_at).format("YYYY-MM-DD"))
            else
              return h("span",{style:"color:var(--subtext3)"},'无')
          }
        },{
          type:"time",
          title:"项目管理",
           key: "is_项目管理",
          width:80,
          render:(h,{row})=>{
            if(row.is_项目管理)
              return h("BaseIcon",{props:{icon:'md-checkmark',color:'darkgreen'},style:'font-weight:bold'})
            else
              return h("span",{style:"color:var(--subtext3)"},'-')
          }
        },{
          type:"time",
          title:"监理",
           key: "is_监理",
          width:80,
          render:(h,{row})=>{
            if(row.is_监理)
              return h("BaseIcon",{props:{icon:'md-checkmark',color:'darkgreen'},style:'font-weight:bold'})
            else
              return h("span",{style:"color:var(--subtext3)"},'-')
          }
        },{
          type:"time",
          title:"招标代理",
           key: "is_招标代理",
          width:80,
          render:(h,{row})=>{
            if(row.is_招标代理)
              return h("BaseIcon",{props:{icon:'md-checkmark',color:'darkgreen'},style:'font-weight:bold'})
            else
              return h("span",{style:"color:var(--subtext3)"},'-')
          }
        },{
          type:"time",
          title:"造价跟审",
           key: "is_造价咨询",
          width:80,
          render:(h,{row})=>{
            if(row.is_造价咨询)
              return h("BaseIcon",{props:{icon:'md-checkmark',color:'darkgreen'},style:'font-weight:bold'})
            else
              return h("span",{style:"color:var(--subtext3)"},'-')
          }
        },{
          type:"time",
          title:"结算审核",
           key: "is_结算",
          width:80,
          render:(h,{row})=>{
            if(row.is_结算)
              return h("BaseIcon",{props:{icon:'md-checkmark',color:'darkgreen'},style:'font-weight:bold'})
            else
              return h("span",{style:"color:var(--subtext3)"},'-')
          }
        },{
          type:"time",
          title:"BIM",
           key: "is_BIM咨询",
          width:80,
          render:(h,{row})=>{
            if(row.is_BIM咨询)
              return h("BaseIcon",{props:{icon:'md-checkmark',color:'darkgreen'},style:'font-weight:bold'})
            else
              return h("span",{style:"color:var(--subtext3)"},'-')
          }
        },{
            key:'buildingArea',
            title:"建筑面积",
            width:100,
            type:'number',
            option:{
                fixed:2,
                unit:'m^2'
            },
            render:(h,{row})=>{
                let area = row.buildingArea
                let unit = h('span',{style:'margin-left:5px;color:var(--subtext2)'},['m',h('sup',2)])
                if(area)
                    return h('span',{},[this.$util.formatSalary(area,1),unit])
                else
                    return h('span','-')
            }
        },{
                      key: "mcharger_id",
            type:'text',
                      group: "管理",
                      title: "片区负责人",
                      width: 120,
                      render(h, { row }) {
                          let domEdit = h("BaseIcon", {
                              props: {
                                  icon: "md-create",
                              },
                              class: "edit-icon",
                              on: {
                                  click() {
                                      that.EditCharger(row);
                                  },
                              },
                              style: {
                                  marginLeft: "5px",
                              },
                          });
                          let domName = h("BaseNameBoard", {
                              props: {
                                  uid: row.mcharger_id,
                              },
                style:'flex-shrink:0'
                          });
                          let domAssistants = h("BaseNameBoards", {
                              props: { uids: row.assistants, maxShow: 2 },
                          });
                          let Divider = h("Divider", {
                              props: { type: "vertical" },
                              style: "",
                          });
                          let domEmpty = h(
                              "div",
                              { style: "color:var(--subtext3);width:30px;text-align:center;" },
                              "无"
                          );
                          return h(
                              "div",
                              { class: "flex-wrap flex-left", style: { padding: "0 10%" } },
                              [
                                  row.mcharger_id ? domName : domEmpty,
                                  domEdit
                              ]
                          );
                      },
                  }, {
                      key: "charger_id",
            type:'text',
                      group: "管理",
                      title: "项目负责人",
                      width: 120,
                      render(h, { row }) {
                          let domEdit = h("BaseIcon", {
                              props: {
                                  icon: "md-create",
                              },
                              class: "edit-icon",
                              on: {
                                  click() {
                                      that.EditCharger(row);
                                  },
                              },
                              style: {
                                  marginLeft: "5px",
                              },
                          });
                          let domName = h("BaseNameBoard", {
                              props: {
                                  uid: row.charger_id,
                              },
                style:'flex-shrink:0'
                          });
                          let domAssistants = h("BaseNameBoards", {
                              props: { uids: row.assistants, maxShow: 2 },
                          });
                          let Divider = h("Divider", {
                              props: { type: "vertical" },
                              style: "",
                          });
                          let domEmpty = h(
                              "div",
                              { style: "color:var(--subtext3);width:30px;text-align:center;" },
                              "无"
                          );
                          return h(
                              "div",
                              { class: "flex-wrap flex-left", style: { padding: "0 10%" } },
                              [
                                  row.charger_id ? domName : domEmpty,
                                  domEdit
                              ]
                          );
                      },
                  },  {
          type: 'number',
          title: "签约总额",
          key: "signed_amount",
          width: 120,
          group: '合约',
          option: {
            sumable: true,
            formatter: e => UTIL.formatSalary(e),
            type: "amount",
            fixed:1
          },
        },  {
          type: 'number',
          title: "合作扣除",
          key: "deduct_amount",
          width: 120,
          group: '合约',
          helper:'外委或联合体合作方扣除金额',
          option: {
            sumable: true,
            formatter: e => UTIL.formatSalary(e),
            type: "amount",
            fixed:1
          },
        }, {
          type: 'number',
          title: "调整金额",
          key: "adjust_amount",
          width: 120,
          group: '合约',
          option: {
            sumable: true,
            formatter: e => UTIL.formatSalary(e),
            type: "amount",
            fixed:1
          },
        },{
          type: 'number',
          title: "营收金额",
          helper:"公司自营收入的实际合同额（即签约金额 - 合作扣除 + 调整金额)",
          key: "income_amount",
          width: 120,
          group: '合约',
          option: {
            sumable: true,
            formatter: e => UTIL.formatSalary(e),
            type: "amount",
            fixed:1
          },
        }, {
          key: "current_node_status",
          title: "当前进度节点",
          group: "管理",
          width: 180,
          type: 'text',
          render:(h,{row})=>{
            let domContent = row.current_node_status || '获取失败'
            if(row.current_node)
                domContent = h('div',{},[
                    h('div',{},row.current_node.name),
                    h('div',{class:'flex-wrap flex-center split5'},
                        [
                            h('span',{style:'color:var(--text2)'},moment(row.current_node.started_at).format('YYYY/MM')),
                            h('span',{},'-'),
                            h('span',{style:'color:var(--text2)'},moment(row.current_node.finished_at).format('YYYY/MM'))
                        ]
                    )
                ])
            let dom = h('div',{style:`padding:2px 6px;background:var(--border);border:1px solid var(--border);border-radius:2px;margin:5px;cursor:pointer;color:${row.current_node_status.includes('无计划')==false?'var(--primary)':'var(--subtext2)'}`},[domContent])
            return h('div',{class:'flex-wrap flex-center',on:{
                click:()=>{
                    this.openProgress(row.id)
                }
            }},[dom])
          }
        },{
          type: 'number',
          title: "上年底总收费额",
          key: "last_year_end_billed",
          width: 120,
          group: '合约',
          option: {
            sumable: true,
            formatter: e => UTIL.formatSalary(e),
            type: "amount",
            fixed:1
          },
        }, {
          type: 'number',
          title: "今年计划开票",
          key: "year_bill_plan_amount",
          width: 120,
          group: '合约',
          option: {
            sumable: true,
            formatter: e => UTIL.formatSalary(e),
            type: "amount",
            fixed:1
          },
        }, {
          type: 'number',
          title: "今年已开票",
          key: "year_billed_amount",
          width: 120,
          group: '合约',
          option: {
            sumable: true,
            formatter: e => UTIL.formatSalary(e),
            type: "amount",
            fixed:1
          },
        },  {
        type: 'text',
        title: "上一笔",
        key: "last_bill",
        sortable:false,
        width: 190,
        group: '合约',
        render: (h, { row }) => {
          let bill = row.last_bill
          if (!bill)
            return h("span", "-")

          let domContent = h("span", { style: "text-align:right" }, bill.note || '-')
          let date = bill.billed_at
          let domDate = date ? h('span', moment(date).format("YYYY-MM-DD")) : "-"
          let domAmount = h("BaseAmount", { props: { value: bill.amount } })
          let domSplit = h("Divider", { props: { type: "vertical" } })
          let domTop = h("div", { class: "flex-wrap flex-between", }, [domDate, domSplit, domAmount])
          return h("div", { class: "flex-col align-end",style:'padding:5px;background:#f0ffee;border:1px solid var(--border);margin:5px;cursor:pointer',on:{
            click:()=>{
                this.openBillPlan(row.id)
            }
          } }, [domTop, domContent])
        }
      }, {
        type: 'text',
        title: "下一笔",
        key: "next_plan",
        
        sortable:false,
        width: 190,
        group: '合约',
        render: (h, { row }) => {
          let bill = row.next_plan
          if (!bill)
            return h("span", "-")

          let domContent = h("span", { style: "text-align:right" }, bill.note || '-')
          let date = bill.plan_finished_at || bill.finished_at
          let domDate = date ? h('span', { style: { color: moment(date).isAfter(moment()) ? 'var(--success)' : 'var(--error)' } }, moment(date).format("YYYY-MM-DD")) : "-"
          let domAmount = h("BaseAmount", { props: { value: bill.amount } })
          let domSplit = h("Divider", { props: { type: "vertical" } })
          let domTop = h("div", { class: "flex-wrap flex-between", }, [domDate, domSplit, domAmount])
          return h("div", { class: "flex-col align-end flex-start",style:'padding:5px;background:#ddf0ff;border:1px solid var(--border);margin:5px;cursor:pointer',on:{
            click:()=>{
                this.openBillPlan(row.id)
            }
          }  }, [domTop, domContent])
        }
      }, {
          key: `target_currentnode_2_${year}`,
          title: "2025年确保支付节点",
          group: "管理",
          width: 140,
          
        }, {
          key: `target_yearbilled_1_${year}`,
          title: "2025年确保收费",
          group: "",
          width: 140,
          type: 'number',
          option: {
            sumable: true,
            formatter: e => UTIL.formatSalary(e),
            type: "amount",
            fixed:1
          },
        },{
          key: `target_currentnode_4_${year}`,
          title: `${year}年争取支付节点`,
          group: "管理",
          width: 140,
        },{
          key: `target_yearbilled_3_${year}`,
          title: `${year}年争取收费`,
          group: "",
          width: 140,
          type: 'number',
          option: {
            sumable: true,
            formatter: e => UTIL.formatSalary(e),
            type: "amount",
            fixed:1
          },
        },{
          key: `target_assistid_5_${year}`,
          title: "收费目标协助人",
          group: "",
          width: 140,
          type: 'user'
        },{
          key: "updated_at",
          title: "最近更新",
          group: "管理",
          width: 80,
          type: 'time'
        },{
        key: "focused_reason",
        group: "管理",
        title: "关注原因",
        width: 300,
        render: (h, { row }) => {
          let mark = h('BaseMark', {
            style: `display:inline;margin-right:5px;`,
            props: {
              value: row.focused
            }
          })
          let edit = h('Icon', {
            props: {
              type: "md-create"
            },
            style: {
              style: "margin-left:5px;cursor:pointer;"
            },
            on: {
              click: () => {
                this.showModalFocus = true
                this.formData = {
                  id: row.id,
                  focused: row.focused,
                  focused_reason: row.focused_reason
                }
              }
            }
          })

          return h('div', { class: "split10", style: `padding:0 10px;text-align:left;color:${!row.focused_reason?'var(--subtext2)':'var(--text2)'}` }, [mark, row.focused_reason || '请输入关注原因', edit])
        }
      },{
        title: "操作",
        key: "tool",
        fixed:'right',
        width:100,
        render:(h,{row})=>{
          let buttons = [
            h('Button',{props:{icon:"md-create",size:"small"},on:{click:()=>{
              this.handleEdit(row.id)
            }}}),
            h('Button',{props:{icon:"md-ionic",size:"small"},on:{click:()=>{
              this.handleEditTarget(row.id)
            }}}),
           
          ]
          return h('div',{class:"flex-wrap split5 flex-center"},buttons)
        }
      },	]
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  </style>