<style lang="less">
.bp-title {
  font-size: 16px;
  margin: 5px 0;
  font-weight: bold;
}

.pd-condition {
  margin: 4px 0;
  border-bottom:1px dashed var(--border);
  flex-wrap: nowrap;
}

.u-index {
  padding: 2px;
  margin: 5px;
  min-width: 20px;
  text-align: center;
  font-size: 10px;
  font-weight: normal;
}

.cm-list-item{
  padding:5px;
  border:1px dashed var(--border);
  border-bottom:none;
  border-color:var(--border);
  overflow:hidden !important;
  position: relative;
  transition: all 0.3s;
  .u-index{
    background:var(--bg2);
    color:var(--text2);
    white-space: nowrap;
    text-align: center;
    margin-right:5px;
    font-size:10px;
    font-weight:normal;
   width:60px;
   max-width:60px;
   flex-grow: 0;
   flex-shrink:0;
    
  }
}

.cm-list-item{
  border-bottom:1px dashed var(--border);
  border-left:2px solid var(--primary);
}

.cm-list-item:hover{
  filter:brightness(1.1);
  box-shadow: 1px 1px 5px var(--subtext3);
  cursor: pointer;
  .u-index{
    color:var(--primary);
    background:var(--bg3);
  }
}

.cm-list-item-finished{
  border-left:2px solid var(--success);
  background:var(--bg2);
}

.cm-list-item-error{
  border-left:2px solid var(--error);
  background:var(--bg2);
}
</style>
<template>
  <div>
    <div class="flex-wrap flex-left split10" style="border:1px solid var(--border);margin:0 10px;padding:10px;">
      <BaseNumboard name="签约总额" v-if="data.adjust_amount > 1" align="flex-start" :value="formatSalaryObject(data.signed_amount).value" :unit="formatSalaryObject(data.signed_amount).unit"  reverse />
      <BaseNumboard :name="`合同总额${data.adjust_amount?'(调)':''}`" align="flex-start" :value="formatSalaryObject(data.amount).value"  :unit="formatSalaryObject(data.amount).unit"  reverse />
      <BaseBoardDivider />
      
      <BaseNumboard name="已开票" align="flex-start" :value="formatSalaryObject(data.billed_amount).value" unit="万" reverse />
      <BaseProgressBoard name="开票比例" align="flex-start" :value="data.bill_percent" />
      <BaseBoardDivider />
      <BaseNumboard name="已到账" align="flex-start" :value="formatSalaryObject(data.transfer_amount).value" reverse  :unit="formatSalaryObject(data.transfer_amount).unit" />
       <BaseProgressBoard name="到账比例"  align="flex-start"  :value="data.transfer_percent" />
     
    </div>
   
   
    <Row :gutter="10" style="padding:0 10px;">
      <Col :span="8" v-if="false">
      <div style="">
         <BaseBoardSubTitle name="收费条款" />
         <BaseEmpty v-if="!data.conditions || data.conditions.length == 0" msg="暂无收费条款">
      </BaseEmpty>
     
      <template v-for="g in pay_conditions_groups">
        <div :key="'p_g_'+g.name" style="margin-bottom:10px">
          <div style="color:var(--primary);margin:5px 0;">{{g.name}}</div>
          <div>
            <template v-for="(b,index) in g.conditions">
              <div class="flex-wrap flex-between flex-line cm-list-item" :key="'pay_c_'+b.id">
                <div style="color:var(--text3);font-size:14px;font-weight:bold;flex-grow:1;width:100%;flex-shrink:1;" class="flex-wrap"><span class="u-index" style="">{{b.business_type.slice(0,2)}}{{index+1}}</span>
                    {{b.detail}} </div>
                <div style="font-weight:bold;color:var(--primary);width:140px;text-align:right;flex-shink:0;flex-grow:0;margin-left:10px;"> ￥{{formatSalary(b.amount)}}
                
                </div>
                <div style="width:40px;flex-shrink:0;margin-left:5px;text-align:right;color:var(--bg1);">{{b.percent}}%</div>

              </div>
            </template>
          </div>
        </div>
      </template>
        
      </div>
      </Col>
      <Col :span="24">
      <BaseEmpty v-if="!data.bill_plans || data.bill_plans.length == 0" msg="暂无收费计划">
      </BaseEmpty>
      <template v-else>
        <div style="">
          <template v-for="(g,i) in plan_years_groups">
            <div :key="`condition_group_${g.id}`">
            <div style="padding:10px 10px;background:var(--bg3);border-bottom:1px dashed var(--primary);cursor:pointer;" :style="`border-color:${g.status_color} !important`"  class="flex-wrap flex-between" >
              <div>
                <div style="font-size:16px"> <BaseIcon icon="ios-arrow-down" :style="`transition:all 0.3s;`" :color="open_index==i?'var(--primary)':''" /> {{g.name}} <span style="background:var(--primary);font-size:10px;padding:1px 3px;color:var(--hover-text);margin-left:10px;" v-if="moment().year() == g.name"> 今年</span></div>
                </div>
                <div class="flex-row align-end">
                <div class="flex-wrap split10">
               
                 
                 <div class="flex-wrap flex-right split10" style="width:480px;color:var(--subtext3);">
                 
                  <div class="flex-wrap" style="padding:0px 5px;background:var(--bg2);margin-right:20px;border:1px solid var(--border)">
                  实际已完成<BaseAmount :value="g.completed" style="width:90px;border-left:1px solid var(--border);margin-left:5px;" /> </div>  
                  <div class="flex-wrap" style="padding:2px 5px;background:var(--bg2);margin-right:20px;border:1px solid var(--border)">
                计划完成<BaseAmount :value="g.amount" style="width:90px;border-left:1px solid var(--border);margin-left:5px;"  />
                </div>
                </div>
                
                <Progress size="small" hide-info :percent="g.percent" status="active" style="width:50px;" :stroke-width="5"></Progress>
                </div>
                
                </div>
            </div>
            <template v-for="(b,index) in g.items">
            <div class="cm-list-item flex-between flex-line" :class="{'cm-list-item-finished':b.finished_at,'cm-list-item-error':!b.finished_at && moment(b.plan_finished_at).isBefore(moment())}" :key="'bp_'+b.id" @click="handleEditPlan(b)" >
              
            <div class="flex-wrap flex-between " style="flex-grow:1;min-width:200px;">
              <div style="color:var(--text3);font-size:15px;font-weight:bold;" class="flex-wrap"><div class="flex-wrap" style="margin-left:20px;" :style="`color:var(--${!b.finished_at && moment(b.plan_finished_at).isBefore(moment())?'error':(b.finished_at?'success':'primary')})`">
                <BaseIcon icon="date" size="15"></BaseIcon> <div style="font-size:14px;margin:0 5px;font-weight:normal;width:80px;flex-shrink:0;">{{b.finished_at && moment(b.finished_at).isValid()?moment(b.finished_at).format("MM/DD"):(b.plan_finished_at?moment(b.plan_finished_at).format("MM/DD"):'无计划时间')}}</div>
              </div>
                
              <div>
              <div :key="b.id" style="font-size:14px;margin:2px 10px;font-weight:bold;color:var(--text3);flex-grow:1;" v-if="b.condition_id"> {{pay_conditions.find(p=>p.id == b.condition_id)?pay_conditions.find(p=>p.id == b.condition_id).detail:b.condition_id}} </div>
             <div :key="'vbin_'+b.id" style="font-size:12px;margin:2px 10px;color:var(--subtext3)"><span style="color:var(--primary)">{{pay_conditions.find(p=>p.id == b.condition_id)?pay_conditions.find(p=>p.id == b.condition_id).business_type:data.type}}</span>  {{b.note}} </div>
             </div>
             </div>
            </div>
            <div class="flex-wrap" >
               
             <div style="font-size:14px;padding:1px 5px;border:1px solid var(--error);margin-left:10px;font-size:10px;border-radius:5px;color:var(--error)" v-if="!b.finished_at && moment(b.plan_finished_at).isBefore(moment())">
                  已逾期
                </div>
                <div v-else-if="b.finished_at" icon="md-checkmark" color="var(--success)"
                style="border:1px solid var(--success);border-radius:5px;font-weight:bold;padding:1px 5px;color:var(--success);font-size:10px;margin-left:10px;" 
              >已完成</div>

              <div v-else style="border:1px solid var(--primary);border-radius:5px;font-weight:bold;padding:1px 5px;color:var(--primary);font-size:10px;margin-left:10px;" >
                计划中
              </div>
              <div style="color:var(--primary);font-size:14px;width:140px;text-align:right;flex-shrink:0;"><BaseAmount :value="b.amount"></BaseAmount></div>
              <div>
              </div>
              </div>
              
              

            </div>
            
          </template>
            </div>
          </template>
          
        </div>
      </template>
      </Col>
      <!-- <Col :span="8">
      
     
      </Col> -->
    </Row>


  </div>
</template>

<script>
import { cloneDeep, pick } from 'lodash'
import moment from 'moment'
moment.locale('zh-CN')
import Draggable from 'vuedraggable'
import UTIL from '@/utils'
import {fix, percent} from '@/utils/calc'
import ProjectCondition from '@/components/cm/Conditions'
import ProjectConditionGroup from '@/components/cm/ConditionGroups'
import ConditionEditor from './EditorConditions'
import ConditionGroupEditor from './EditorConditionSplit'
import AdjustEditor from './EditorAdjustRecords'
export default {
  data() {
    return {
      showBillPlan: false,
      showEditor: false,
      showCondition: false,
      bill_plans: [],
      open_index:0,
      formData: {},
      model:{},
      showBills:false,
      showError:false,
      showConditionEditor:false,
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.idGlobal = 1
    setTimeout(()=>{
      let index = this.plan_years_groups.findIndex(v=>v.name == moment().year())
      this.open_index = index
    },500)
  },
  components:{Draggable,ProjectCondition,ProjectConditionGroup,ConditionEditor,ConditionGroupEditor,AdjustEditor},
  computed: {
    plan_years_groups(){
      
       let g = {}
       if(!Array.isArray(this.data.bill_plans))
        return []
      this.data.bill_plans.forEach(c=>{
        
        let type = c.finished_at?(moment(c.finished_at).format("YYYY")):(c.plan_finished_at?moment(c.plan_finished_at).format("YYYY"):'未计划')
        if(g[type]){
          g[type].items.push(c)
        }else{
          g[type] = {
            name:type,
            items:[c]
          }
        }
      })
      let groups =  Object.values(g)
      groups.forEach(v=>{
        v.amount = v.items.reduce((acc,item)=>acc+item.amount,0)
        v.completed = v.items.filter(v=>v.finished_at).reduce((acc,item)=>acc+item.amount,0)
        v.percent = parseInt(v.completed * 100 / v.amount)
        v.status_color = 'var(--primary)'
        if(Math.abs(v.completed - v.amount) <= 0.1)
          v.status_color = 'var(--success)'
        else if(v.year != '未计划' && parseInt(v.year) < moment().year())
          v.status_color = 'var(--error)'
      })
      console.log(groups)
      return groups
    },
    pay_conditions_groups(){
      let g = {}
      this.pay_conditions.forEach(c=>{
        let type = c.business_type || '未编组'
        if(g[type]){
          g[type].conditions.push(c)
        }else{
          g[type] = {
            name:c.business_type,
            conditions:[c]
          }
        }
      })
      return Object.values(g)
    },
    isProjectFinished() {
			return (
				this.data.status == "已结束"
			);
		},
    id(){
      return this.$route.params.id
    },
    errors_descriptions(){
      return {
        "计划金额与合同总额不符":"我们要求收费计划的总额与合同总额一致, 请检查收费计划是否创建完整",
        "已完成的计划金额与开票金额不符":"已标记为完成的计划总额与已开票的总额不一致，请进行修改，如果开票存在多笔一起开或者多笔收费的情况，需要如实对计划指标进行拆分，并将已开票部分标记为完成"
      }
    },
    errors(){
      let plan_sum = this.data.plan_amount
      let plan_finished = this.data.plan_finished_amount
      if(this.showEditor){
        plan_sum = this.plan_sum
        plan_finished = this.plan_finished_sum
      }

      let e = []
      if(plan_sum <= this.data.amount - 0.1 || plan_sum >= this.data.amount + 0.1){
        e.push(`计划金额与合同总额不符`)
      }else if(plan_finished + 0.1 < this.data.bill_amount || plan_finished -0.1 > this.data.bill_amount){
        e.push(`已完成的计划金额与开票金额不符`)
      }

      return e
    },

    lastBill() {
      if (!Array.isArray(this.data.bills))
        return {}
      if (this.data.bills && this.data.bills.length > 0)
        return this.data.bills[this.data.bills.length - 1]
    },
    nextPlan() {
      if (!Array.isArray(this.data.bill_plans))
        return {}
      for (let i = 0; i < this.data.bill_plans.length; i++)
        if (moment(this.data.bill_plans[i].plan_finished_at).isAfter(moment()))
          return this.data.bill_plans[i]
    },
    plan_sum() {
      return fix(this.bill_plans.reduce((c, a) => c + a.amount, 0),2)
    },
    plan_finished_sum(){
      return fix(this.bill_plans.filter(v=>v.finished_at).reduce((c, a) => c + a.amount, 0),2)
    },
    plan_unfinished_sum(){
      return fix(this.plan_sum - this.plan_finished_sum,2)
    },
    plan_finished_percent(){
      return percent(this.plan_finished_sum,this.plan_sum)
    },
    pay_conditions() {
      return this.data.conditions || []
    },
    special_conditions() {
      return this.data.conditions ? this.data.conditions.filter(v => v.type == '特殊条款') : []
    }
  },
  methods: {
    handleEditCondition(e){
      this.model = cloneDeep(pick(this.data,['signed_amount','amount','condition_groups','splited','id','conditions','contract_adjusts','adjust_amount']))
      this.showConditionEditor=true
    },
    handleSaveCondition(){
      let shrinked_model = UTIL.compare_patch_object(this.data,this.model)
      
      this.$api.patch("cm/projects/"+this.model.id,shrinked_model).then(res=>{
        this.$emit('on-update')
        this.showConditionEditor = false
        this.model = {}
      })
    }, 
    formatSalaryObject(e){
      return UTIL.formatSalaryObject(e)
    },
    formatFullSalary(e){
      return UTIL.formatSalarySemicolon(e)
    },
    formatSalary(e){
      return UTIL.formatSalary(e)
    },
    createPlan(){
      this.bill_plans = this.pay_conditions.map(this.handleClone)
    },
    handleSavePlans() {
      let patched = UTIL.compare_patched(this.data.bill_plans, this.bill_plans, ['amount', 'condition_id', 'note', 'plan_finished_at','finished_at'])
      patched.create.forEach(v=>{
        delete v.id
      })
      
      if(patched.empty){
         this.showEditor = false
        this.bill_plans = []
         return
      }

      this.$api.patch('/projects/'+this.id+'?q=billplan',{bill_plans:patched}).then(res=>{
        this.showEditor = false
        this.bill_plans = []
         this.$emit("on-update")
      }).catch(e=>{
        this.Error(e?e.message:'错误')
      })
    },
    handleSavePlan(){
      this.$api.patch('/projects/'+this.id+'?q=billplan',{bill_plans:{
        update:[{id:this.formData.id,finished_at:this.formData.finished_at || null}]
      }}).then(res=>{
        //this.data.bill_plans = res.data.data
       
        this.$emit("on-update")
        this.showEditor = false
        this.showBillPlan = false
        this.bill_plans = []
      }).catch(e=>{
        this.Error(e?e.message:'错误')
      })
    },
    handleEdit() {
      this.bill_plans = cloneDeep(this.data.bill_plans)
      this.showEditor = true
    },
    handleEditPlan(plan) {
      this.formData = { ...plan }
      if(!this.formData.finished_at){
        this.formData.finished_at = this.formData.plan_finished_at || moment().format()
      }
      this.showBillPlan = true
    },
    handleDeletePlan(i) {
       this.bill_plans.splice(i, 1)

    },
    handleAdd(){
      this.bill_plans.push(this.handleClone({}))
    },
    handleClone({ id, amount, detail }) {
      this.idGlobal++
      let obj = {
        id: 'id'+this.idGlobal,
        condition_id: id,
        amount:amount || 0
      }
      return obj

    },
    exportToExcel(){

    }
  }
}
</script>
<style lang="less">

</style>
