<style lang="less">
.ivu-progress-inner {
  overflow: hidden;
}
</style>

<template>
	<BaseSegment title="指标评价" style="margin: 0px; position: relative">
		<Spin fix v-if="loading">
			<div class="flex-col align:center" style="font-size: 15px">
				<BaseLoading style="margin: 0px !important"> </BaseLoading>
				数据测算中
			</div>
		</Spin>
		<div v-if="errors.length > 0">
			<div style="font-size: 10px; color: var(--error); margin-bottom: 5px">
				项目数据存在以下错误，无法测算项目指标
				<a @click="$emit('on-edit')" style="margin: 0 5px">立即修改 ></a>
				<a @click="
						check();
						calc();
					">刷新 ></a>
			</div>
			<template v-for="(e, i) in errors">
				<div :key="'error_'+e.key+i" class="flex-wrap" style="margin-bottom: 5px">
					<div style="width: 150px">
						{{ i + 1 }} -
						<span style="color: red; font-weight: bold">{{ e.key }}</span>
					</div>
					<div style="color: var(--subtext3)">{{ e.value }}</div>
				</div>
			</template>
		</div>
		<template v-else>
			
			<div v-if="no_plan" style="padding: 10px; font-size: 10px; color: var(--warning)">
				<BaseIcon icon="md-alert" /> 该项目未填写签约计划，指标计算可能会有偏差
			</div>
			<div slot="shrink" style="padding: 20px">
				<Row :gutter="20">
					<Col :span="10">
					<BaseBoardSubTitle name="总体">
					<div style="color: var(--subtext3); padding-right: 20px" v-if="!no_plan">
						当前 <a @click="showPlan(monthData.last_version)">{{monthData.last_version == "origin"?"签约计划":monthData.last_version}}</a>
						<span style="padding-left: 20px; padding-right: 10px" v-if="!no_adjust_plan">对比 <a @click="showPlan('origin')">原始计划</a></span>
					</div>
				</BaseBoardSubTitle>
				<CellGroup>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">进度</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-col align-end">
								<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
									<span style="color: var(--primary)">{{
										total.actual_duration
										}}</span>
									/ <span style="color: var(--primary)">{{ total.plan_duration }}</span> 天
								</div>
								<Progress style="width: 200px" :percent="total.duration_percent" status="active" />
							</div>
							<div class="flex-col align-start" style="width: 140px" v-if="!no_plan && !no_adjust_plan">
								<div>
									<span :style="`color:var(--${
												total.duration_offset > 0 ? 'error' : 'success'
											})`">{{ total.duration_offset == 0?'-':(total.duration_offset > 0 ? "滞后" : "超前")}}</span>
									{{ abs(total.duration_offset) }} 天
								</div>
								<Progress style="width: 140px" :percent="total.duration_offset_percent" status="active" />
							</div>
						</div>
					</Cell>

					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">产值</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-col align-end">
								<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
									<BaseAmount :value="total.actual_production" /> /
									<BaseAmount :value="total.plan_production" />
								</div>
								<Progress style="width: 200px" :percent="total.production_percent" status="active" />
							</div>
							<div class="flex-col align-start" style="width: 140px" v-if="!no_plan && !no_adjust_plan">
								<div>
									<span :style="`color:var(--${
												value.adjust_amount < 0 ? 'error' : 'success'
											})`">{{value.adjust_amount == 0? '-':(value.adjust_amount > 0?'增加':'减少')}}</span> {{abs(value.adjust_amount/ 10000)}} 万元
								</div>
								<Progress style="width: 140px" :percent="total.prodcution_offset_percent" :stroke-color="total.production_offset_percent > 0?'var(--success)':'var(--error)'" status="active" />
							</div>
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">收费</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-col align-end">
								<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
									<BaseAmount :value="total.actual_bill" /> /
									<BaseAmount :value="total.plan_bill" />
								</div>
								<Progress style="width: 200px" :percent="total.bill_percent" status="active" />
							</div>
							<div class="flex-col align-start" style="width: 140px" v-if="!no_plan && !no_adjust_plan">
								<div>
									- 万元
								</div>
								<Progress style="width: 140px" :percent="total.bill_offset_percent" :stroke-color="total.bill_offset > 0?'var(--success)':'var(--error)'" status="active" />
							</div>
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">人员</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-col align-end">
								<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
									<span style="color: var(--primary);">{{total.actual_hrv}}</span> <template v-if="!no_plan">/ {{total.plan_hrv}}</template> 人月
								</div>
								<Progress v-if="!no_plan" style="width: 200px" :percent="total.hrv_percent" status="active" />
							</div>
							<div class="flex-col align-start" style="width: 140px" v-if="!no_plan && !no_adjust_plan">
								<div>
									<span :style="`color:var(--${
												total.offset_hrv > 0 ? 'error' : 'success'
											})`">{{total.offset_hrv > 0?'超出':'节约'}}</span> {{total.offset_hrv}} 人月
								</div>
								<Progress style="width: 140px" :percent="total.offset_hrv_percent" status="active" />
							</div>
						</div>
					</Cell>
					</CellGroup>
					</Col>
					<Col :span="7" style="background:var(--bg2);padding:10px;border-top-left-radius:10px;border-bottom-left-radius:10px;">
				<BaseBoardSubTitle :name="`年度 - ${yearData.year}`">
					<div style="color: var(--subtext3)" v-if="!no_plan">当前 {{monthData.last_version == "origin"?"签约计划":monthData.last_version}}</div>
				</BaseBoardSubTitle>
				<CellGroup>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">进度</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<span style="color: var(--primary)">{{yearData.actual_duration}} </span> / {{yearData.plan_duration}} 天
							</div>
							<Progress style="width: 200px" :percent="yearData.duration_percent" status="active" />
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">产值</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary); margin-right: 5px">
									<BaseAmount :value="yearData.actual_production" /></span>
								/
								<BaseAmount :value="yearData.plan_production" style="margin-left: 5px" />
							</div>
							<Progress style="width: 200px" :percent="yearData.production_percent" status="active" />
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">收费</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary); margin-right: 5px">
									<BaseAmount :value="yearData.actual_bill" /></span>
								/
								<BaseAmount :value="yearData.plan_bill" style="margin-left: 5px" />
							</div>
							<Progress style="width: 200px" :percent="yearData.bill_percent" status="active" />
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">人员</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary)">{{yearData.actual_hrv}}</span> <span v-if="!no_plan">/ {{yearData.plan_hrv}}</span> 人月
							</div>
							<Progress v-if="!no_plan" style="width: 200px" :percent="yearData.hrv_percent" status="active" />
						</div>
					</Cell>
					</CellGroup>
					</Col>
					<Col :span="7" style="background:var(--bg2);padding:10px;border-top-right-radius:10px;border-top-bottom-radius:10px;border-left:2px dashed var(--border);">
					<BaseBoardSubTitle :name="`月度 - ${monthData.month}月`">
					<div style="color: var(--subtext3)" v-if="!no_plan">当前 {{monthData.last_version == "origin"?"签约计划":monthData.last_version}}</div>
				</BaseBoardSubTitle>
				<CellGroup>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">进度</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary)">{{monthData.actual_duration}}</span> / {{monthData.plan_duration}} 天
							</div>
							<Progress style="width: 200px" :percent="monthData.duration_percent" status="active" />
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">产值</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary); margin-right: 5px">
									<BaseAmount :value="monthData.actual_production" /></span>
								/
								<BaseAmount :value="monthData.plan_production" style="margin-left: 5px" />
							</div>
							<Progress style="width: 200px" :percent="monthData.production_percent" status="active" />
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">收费</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary); margin-right: 5px">
									<BaseAmount :value="monthData.actual_bill" /></span>
								/
								<BaseAmount :value="monthData.plan_bill" style="margin-left: 5px" />
							</div>
							<Progress style="width: 200px" :percent="monthData.bill_percent" status="active" />
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">人员</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary)">{{monthData.actual_hrv}}</span> <span v-if="!no_plan">/ {{monthData.plan_hrv}} </span> 人月
							</div>
							<Progress style="width: 200px" v-if="!no_plan" :percent="monthData.hrv_percent" status="active" />
						</div>
					</Cell>
					</CellGroup>
					</Col>
				</Row>
			</div>
			<div slot="extra" class="flex-wrap" style="color: var(--subtext3)">
				<!-- 时间轴 {{current_month}} / {{total_months - 1}}
				<div style="margin-left:20px">
					<div class="flex-wrap flex-center" style="padding:0 50px">
						<Button size="small" style="margin: 0 5px" icon="ios-arrow-back" v-if="current_month>0" @click="
						current_month = current_month-1;
						handleChangeMonth(current_month)
					">
						</Button>
						<span style="color: var(--primary); margin: 0 5px">{{
							moment(current_date).format("YYYY-MM-DD")
							}}</span><Button size="small" style="margin: 0 5px" v-if="current_date != today && !finished" @click="
						current_date = today;
						check();calc();
					">本月
						</Button>
						<Button size="small" style="margin: 0 5px" icon="ios-arrow-forward" v-if="current_month < total_months" @click="
						current_month = current_month+1;
						handleChangeMonth(current_month)
					">
						</Button>
					</div>
					<Slider style="width:300px;margin-left: 10px; margin-right: 20px" v-model="current_month" :max="total_months - 1" transfer :tip-format="formatSlideTip" :step="1" @on-change="handleChangeMonth"></Slider>
				</div> -->
			</div>
			<div>
			<div v-if="value.target_yearbilled_1_2025">
				<BaseBoardSubTitle :name="`${moment().year()}收费目标`"></BaseBoardSubTitle>
				<Row :gutter="20" style="margin-bottom:10px;">
					<Col :span="12">
					<div style="border:1px solid var(--border);padding:10px;border-radius:10px;">
						<div class="flex-wrap flex-center" style="font-weight:bold;color:var(--error);font-size:16px;margin-bottom:5px;">确保完成</div>
						<div class="flex-wrap flex-around">
							<div class="flex-wrap">
								<div>收费节点</div>
								<div style="margin-left:10px;font-weight:bold;"
								:style="value.target_currentnode_2_2025?'color:var(--primary)':'color:var(--subtext2)'"
								>{{value.target_currentnode_2_2025 || '未指定' }} </div>
							</div>
							<div class="flex-wrap" style="margin-left:100px;">
								<div>收费金额</div>
								<div style="margin-left:10px;font-weight:bold;" :style="value.target_yearbilled_1_2025?'font-size:20px;color:var(--primary)':'color:var(--subtext2)'"><BaseAmount :value="value.target_yearbilled_1_2025" /></div>
							</div>
						</div>
						
					</div>
					</Col>
					<Col :span="12">
						<div style="border:1px solid var(--border);padding:10px;border-radius:10px;">
							<div class="flex-wrap flex-center" style="font-weight:bold;color:var(--error);font-size:16px;margin-bottom:5px;">争取完成</div>
							<div class="flex-wrap flex-around">
								<div class="flex-wrap">
								<div>收费节点</div>
								<div style="margin-left:10px;font-weight:bold;" :style="value.target_currentnode_4_2025?'color:var(--primary)':'color:var(--subtext2)'">{{value.target_currentnode_4_2025 || '未指定' }}</div>
							</div>
							<div class="flex-wrap" style="margin-left:100px;">
								<div>收费金额</div>
								<div style="margin-left:10px;font-weight:bold;" :style="value.target_yearbilled_3_2025?'font-size:20px;color:var(--primary)':'color:var(--subtext2)'">
									<BaseAmount :value="value.target_yearbilled_3_2025" />
								</div>
							</div>
						</div>
						</div>
					</Col>
				</Row>
			</div>
			<Row :gutter="20" style="margin-top:10px;">
				<Col :span="10">
				<BaseBoardSubTitle name="总体">
					<div style="color: var(--subtext3); padding-right: 20px" v-if="!no_plan">
						当前 <a @click="showPlan(monthData.last_version)"><BaseIcon icon="table" :size="22" /> {{monthData.last_version == "origin"?"签约计划":monthData.last_version}}</a>
						<span style="padding-left: 20px; padding-right: 10px" v-if="!no_adjust_plan">对比 <a @click="showPlan('origin')"><BaseIcon icon="table" :size="22" /> 签约计划</a></span>
					</div>
				</BaseBoardSubTitle>
				<CellGroup>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">进度</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-col align-end">
								<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
									<span style="color: var(--primary)">{{
										total.actual_duration
										}}</span>
									/ {{ total.plan_duration }} 天
								</div>
								<Progress style="width: 200px" :percent="total.duration_percent" status="active" />
							</div>
							<div class="flex-col align-start" style="width: 140px" v-if="!no_plan && !no_adjust_plan">
								<div>
									<span :style="`color:var(--${
												total.duration_offset > 0 ? 'error' : 'success'
											})`">{{ total.duration_offset == 0?'-':(total.duration_offset > 0 ? "滞后" : "超前")}}</span>
									{{ abs(total.duration_offset) }} 天
								</div>
								<Progress style="width: 140px" :percent="total.duration_offset_percent" status="active" />
							</div>
						</div>
					</Cell>

					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">产值</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-col align-end">
								<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
									<BaseAmount :value="total.actual_production" /> /
									<BaseAmount :value="total.plan_production" />
								</div>
								<Progress style="width: 200px" :percent="total.production_percent" status="active" />
							</div>
							<div class="flex-col align-start" style="width: 140px" v-if="!no_plan && !no_adjust_plan">
								<div>
									<span :style="`color:var(--${
												value.adjust_amount < 0 ? 'error' : 'success'
											})`">{{value.adjust_amount == 0? '-':(value.adjust_amount > 0?'增加':'减少')}}</span> {{abs(value.adjust_amount/ 10000)}} 万元
								</div>
								<Progress style="width: 140px" :percent="total.prodcution_offset_percent" :stroke-color="total.production_offset_percent > 0?'var(--success)':'var(--error)'" status="active" />
							</div>
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">收费</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-col align-end">
								<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
									<BaseAmount :value="total.actual_bill" /> /
									<BaseAmount :value="total.plan_bill" />
								</div>
								<Progress style="width: 200px" :percent="total.bill_percent" status="active" />
							</div>
							<div class="flex-col align-start" style="width: 140px" v-if="!no_plan && !no_adjust_plan">
								<div>
									- 万元
								</div>
								<Progress style="width: 140px" :percent="total.bill_offset_percent" :stroke-color="total.bill_offset > 0?'var(--success)':'var(--error)'" status="active" />
							</div>
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">人员</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-col align-end">
								<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
									<span style="color: var(--primary);">{{total.actual_hrv}}</span> <template v-if="!no_plan">/ {{total.plan_hrv}}</template> 人月
								</div>
								<Progress v-if="!no_plan" style="width: 200px" :percent="total.hrv_percent" status="active" />
							</div>
							<div class="flex-col align-start" style="width: 140px" v-if="!no_plan && !no_adjust_plan">
								<div>
									<span :style="`color:var(--${
												total.offset_hrv > 0 ? 'error' : 'success'
											})`">{{total.offset_hrv > 0?'超出':'节约'}}</span> {{total.offset_hrv}} 人月
								</div>
								<Progress style="width: 140px" :percent="total.offset_hrv_percent" status="active" />
							</div>
						</div>
					</Cell>
					
					<Cell   v-if="!no_plan" style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">计划人均产值</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<BaseAmount :value="total.plan_hra_production" />
							</div>
							<div class="flex-col align-start" style="width: 140px" v-if="!no_plan && !no_adjust_plan">
								<div class="flex-wrap">
									<BaseIcon style="color: var(--primary); margin-right: 5px" :icon="total.plan_hra_production_offset > 0?'md-arrow-up':'md-arrow-up'" />
									<BaseAmount :value="abs(total.plan_hra_production_offset)" style="margin-right:10px" /> ({{total.plan_hra_percent}}%)
								</div>
							</div>
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">当前人均产值</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<BaseAmount :value="total.actual_hra_production" />
							</div>
							<!-- <div class="flex-col align-start" style="width: 140px" v-if="!no_plan">
								<div class="flex-wrap">
									<BaseIcon style="color: var(--primary); margin-right: 5px" icon="md-arrow-up" />
									2.3 万 (5%)
								</div>
							</div> -->
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">当前人均收费</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<BaseAmount :value="total.actual_hra_bill" />
							</div>
							<!-- <div class="flex-col align-start" style="width: 140px" v-if="!no_plan">
								<div class="flex-wrap">
									<BaseIcon style="color: var(--primary); margin-right: 5px" icon="md-arrow-up" />
									2.3 万 (5%)
								</div>
							</div> -->
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
								margin-top: 20px;
							">
						<div class="flex-wrap empher">施工进度（建安工程量）</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-col align-end">
								<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
									<BaseAmount :hide-rmb="true" :value="total.actual_bp" /> <span style="margin-left:3px;line-height:14px;">/</span>
									<BaseAmount style="line-height:14px;" :hide-rmb="true" :value="total.plan_bp" />
								</div>
								<Progress style="width: 200px" :percent="total.bp_percent" status="active" />
							</div>
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">当前人均工程量</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<BaseAmount :value="total.actual_hra_bp" />
							</div>
							<!-- <div class="flex-col align-start" style="width: 140px" v-if="!no_plan">
								<div class="flex-wrap">
									<BaseIcon style="color: var(--primary); margin-right: 5px" icon="md-arrow-up" />
									2.3 万 (5%)
								</div>
							</div> -->
						</div>
					</Cell>
				</CellGroup>
				</Col>
				<Col :span="7" style="background:var(--bg2);padding:10px;border-top-left-radius:10px;border-bottom-left-radius:10px;">
				<BaseBoardSubTitle :name="`年度 - ${yearData.year}`">
					<div style="color: var(--subtext3)" v-if="!no_plan">当前 {{monthData.last_version == "origin"?"签约计划":monthData.last_version}}</div>
				</BaseBoardSubTitle>
				<CellGroup>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">进度</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<span style="color: var(--primary)">{{yearData.actual_duration}} </span> / {{yearData.plan_duration}} 天
							</div>
							<Progress style="width: 200px" :percent="yearData.duration_percent" status="active" />
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">产值</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary); margin-right: 5px">
									<BaseAmount :value="yearData.actual_production" /></span>
								/
								<BaseAmount :value="yearData.plan_production" style="margin-left: 5px" />
							</div>
							<Progress style="width: 200px" :percent="yearData.production_percent" status="active" />
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">收费</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary); margin-right: 5px">
									<BaseAmount :value="yearData.actual_bill" /></span>
								/
								<BaseAmount :value="yearData.plan_bill" style="margin-left: 5px" />
							</div>
							<Progress style="width: 200px" :percent="yearData.bill_percent" status="active" />
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">人员</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary)">{{yearData.actual_hrv}}</span> <span v-if="!no_plan">/ {{yearData.plan_hrv}}</span> 人月
							</div>
							<Progress v-if="!no_plan" style="width: 200px" :percent="yearData.hrv_percent" status="active" />
						</div>
					</Cell>
					
					<Cell   v-if="!no_plan" style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">计划人均产值</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<BaseAmount :value="yearData.plan_hra_production" />
							</div>

						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">当前人均产值</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<BaseAmount :value="yearData.actual_hra_production" />
							</div>
							<!-- <div class="flex-col align-start" style="width: 140px" v-if="!no_plan">
								<div class="flex-wrap">
									<BaseIcon style="color: var(--primary); margin-right: 5px" icon="md-arrow-up" />
									2.3 万 (5%)
								</div>
							</div> -->
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">当前人均收费</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<BaseAmount :value="yearData.actual_hra_bill" />
							</div>
							<!-- <div class="flex-col align-start" style="width: 140px" v-if="!no_plan">
								<div class="flex-wrap">
									<BaseIcon style="color: var(--primary); margin-right: 5px" icon="md-arrow-up" />
									2.3 万 (5%)
								</div>
							</div> -->
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								margin-top: 20px;
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">年度工程量</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-col align-end">
								<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
									<BaseAmount :value="yearData.actual_bp" />
								</div>
							</div>
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">当前人均工程量</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<BaseAmount :value="yearData.actual_hra_bp" />
							</div>
							<!-- <div class="flex-col align-start" style="width: 140px" v-if="!no_plan">
								<div class="flex-wrap">
									<BaseIcon style="color: var(--primary); margin-right: 5px" icon="md-arrow-up" />
									2.3 万 (5%)
								</div>
							</div> -->
						</div>
					</Cell>
				</CellGroup>
				</Col>
				<Col :span="7" style="background:var(--bg2);padding:10px;border-top-right-radius:10px;border-top-bottom-radius:10px;border-left:2px dashed var(--border);">
				<BaseBoardSubTitle :name="`月度 - ${monthData.month}月`">
					<div style="color: var(--subtext3)" v-if="!no_plan">当前 {{monthData.last_version == "origin"?"签约计划":monthData.last_version}}</div>
				</BaseBoardSubTitle>
				<CellGroup>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">进度</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary)">{{monthData.actual_duration}}</span> / {{monthData.plan_duration}} 天
							</div>
							<Progress style="width: 200px" :percent="monthData.duration_percent" status="active" />
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">产值</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary); margin-right: 5px">
									<BaseAmount :value="monthData.actual_production" /></span>
								/
								<BaseAmount :value="monthData.plan_production" style="margin-left: 5px" />
							</div>
							<Progress style="width: 200px" :percent="monthData.production_percent" status="active" />
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">收费</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary); margin-right: 5px">
									<BaseAmount :value="monthData.actual_bill" /></span>
								/
								<BaseAmount :value="monthData.plan_bill" style="margin-left: 5px" />
							</div>
							<Progress style="width: 200px" :percent="monthData.bill_percent" status="active" />
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">人员</div>
						<div slot="extra" class="flex-col align-start" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<span style="color: var(--primary)">{{monthData.actual_hrv}}</span> <span v-if="!no_plan">/ {{monthData.plan_hrv}} </span> 人月
							</div>
							<Progress style="width: 200px" v-if="!no_plan" :percent="monthData.hrv_percent" status="active" />
						</div>
					</Cell>
					
					<Cell   v-if="!no_plan" style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">计划人均产值</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<BaseAmount :value="monthData.plan_hra_production" />
							</div>

						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">当前人均产值</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<BaseAmount :value="monthData.actual_hra_production" />
							</div>
							<!-- <div class="flex-col align-start" style="width: 140px" v-if="!no_plan">
								<div class="flex-wrap">
									<BaseIcon style="color: var(--primary); margin-right: 5px" icon="md-arrow-up" />
									2.3 万 (5%)
								</div>
							</div> -->
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">当前人均收费</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<BaseAmount :value="monthData.actual_hra_bill" />
							</div>
							<!-- <div class="flex-col align-start" style="width: 140px" v-if="!no_plan">
								<div class="flex-wrap">
									<BaseIcon style="color: var(--primary); margin-right: 5px" icon="md-arrow-up" />
									2.3 万 (5%)
								</div>
							</div> -->
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								margin-top: 20px;
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">月度工程量</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:50px">
								<div>
									<BaseAmount :value="monthData.actual_bp" />
								</div>
							</div>
						</div>
					</Cell>
					<Cell   style="
								background: var(--bg3);
								color: var(--text3);
								border-bottom: 1px dashed var(--border);
							">
						<div class="flex-wrap empher">当前人均工程量</div>
						<div slot="extra" class="flex-wrap flex-line" style="font-size: 12px">
							<div class="flex-wrap flex-right split5" style="width:100%;padding-right:55px">
								<BaseAmount :value="monthData.actual_hra_bp" />
							</div>
							<!-- <div class="flex-col align-start" style="width: 140px" v-if="!no_plan">
								<div class="flex-wrap">
									<BaseIcon style="color: var(--primary); margin-right: 5px" icon="md-arrow-up" />
									2.3 万 (5%)
								</div>
							</div> -->
						</div>
					</Cell>
				</CellGroup>
				</Col>
			</Row>
		</div>
		</template>

		<Modal transfer v-model="modalPlan" title="总控计划" footer-hide :width="1200">
			{{ modalPlan_version }}
		</Modal>
	</BaseSegment>
</template>

<script>
import UTIL from "@/utils";
import moment from "moment";
import {fix,percent,time_duration,time_int_duration,time_unit} from '@/utils/calc'
import { pick, cloneDeep } from 'lodash'
export default {
	data() {
		return {
			month: {},
			year: {},
			loading: false,
			today: moment().format("YYYY/MM/DD"),
			current_month: 0,
			current_date: moment().format("YYYY/MM/DD"),
			total_months: 0,
			total_date: "",
			start_date: "",
			currentMonth: "",
			monthData: {},
			yearData: {},
			total: {},
			errors: [],
			modalPlan:false,
			modalPlan_version:'origin'
		};
	},
	mounted() {
		this.check();
		this.calc();
	},
	watch: {
		value: {
			handler(v) {
				this.check();
				this.calc();
			},
		},
	},
	props: {
		value: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		no_plan() {
			return !this.value.nodes || this.value.nodes.length == 0;
		},
		no_adjust_plan() {
			return !this.value.nodes || this.value.nodes.filter(v => v.version != 'origin').length == 0
		},
		finished() {
			return this.value.completed_at && moment().isAfter(this.value.completed_at)
		}
	},
	methods: {
		showPlan(version){
			this.modalPlan = true
			this.modalPlan_version = version
		},
		formatSlideTip(e) {
			return moment(this.current_date).format("YYYY/MM");
		},
		handleChangeMonth(e) {
			if (this.current_month >= this.total_months)
				this.current_date = this.total_date;
			else {
				if (e == this.total_months)
					this.current_date = moment(this.today).isAfter(this.total_date) ? this.total_date : this.today
				else {
					if (e == 0)
						this.current_date = moment(this.start_date).format("YYYY/MM/DD")
					else {

						this.current_date = moment(this.start_date)
							.endOf("month")
							.add("month", e - 1)
							.format("YYYY/MM/DD")
					}
				}
			}
			this.calc()
		},
		check() {
			let errors = [];

			let p = this.value;

			if (this.no_plan) {
				if (!p.started_at)
					errors.push({
						key: "服务开始时间",
						value: "我们公司自己的服务开始时间，未填写此项则无法测算项目产值",
					});
				// 无调整计划
				if (!p.finished_at)
					errors.push({
						key: "服务结束时间",
						value: "没有调整计划，且未填写项目服务结束时间",
					});
				if (!p.operated_at)
					errors.push({
						key: "开工时间",
						value: "无调整计划时，未填写开工时间会无法计算较为准确的产值数据",
					});

				if (!p.completed_at)
					errors.push({
						key: "竣工时间",
						value: "无调整计划时，未填写竣工时间会无法计算较为准确的产值数据",
					});
			} else {
				// 有调整计划
				let origin = {}
				let versions_map = {}
				let versions = []
				let last = []
				if (Array.isArray(p.nodes)) {
					let origin = {
						version: "origin",
						nodes: p.nodes.filter(v => v.version == 'origin')
					}
					origin.nodes.sort((a, b) =>
						moment(a.plan_started_at).isAfter(b.plan_started_at) ? 1 : -1
					);
					p.nodes.filter(v => v.version != 'origin').forEach(n => {
						if (n.version && versions_map[n.version]) {
							versions_map[n.version].nodes.push(n)
						} else {
							versions_map[n.version] = {
								version: n.version,
								nodes: [n]
							}
						}
					})

					versions = Object.values(versions_map)
					versions.sort((a, b) => moment(a.version, 'YYYY-MM').isAfter(b.version, 'YYYY-MM') ? 1 : -1)
					
					let tmp_origin = cloneDeep(origin.nodes)
					versions.forEach(v => {
						v.nodes.sort((a, b) =>
							moment(a.plan_started_at).isAfter(b.plan_started_at) ? 1 : -1
						);
						v.nodes.forEach((n, i) => {
							let origin_node = tmp_origin.find(t => t.id == n.version_to)
							if (origin_node) {
								origin_node.hr = n.hr
								if (i == 0 && n.plan_started_at)
									origin_node.plan_started_at = n.plan_started_at
								if (n.plan_duration)
									origin_node.plan_duration = n.plan_duration
								origin_node.version = n.version
							}
						})
						v.nodes = cloneDeep(tmp_origin)
						v.nodes.forEach((n, i) => {
							if (i == 0)
								n.started_at = p.started_at
							n.finished_at = moment(n.started_at).add(n.plan_duration, 'days').format()
							if (i != v.nodes.length - 1)
								v.nodes[i + 1].started_at = n.finished_at
						})
					})


					last = versions.length > 0 ? versions[versions.length - 1] : origin
					
					
					let origin_started_at = origin.nodes[0].started_at
					let origin_finished_at = origin.nodes[last.nodes.length - 1].finished_at
					let last_started_at = last.nodes[0].started_at
					let last_finished_at = last.nodes[last.nodes.length - 1].finished_at

					this.current_date = moment().isAfter(last_finished_at) ? moment(last_finished_at).format("YYYY-MM-DD") : moment().format("YYYY/MM/DD")
					this.start_date = moment(last_started_at).format("YYYY/MM/DD");
					this.total_date = moment(last_finished_at).format("YYYY/MM/DD")

					this.total_months = Math.ceil(
						moment
							.duration(
								moment(this.total_date) - moment(this.start_date)
							)
							.as("months")+1
					);
					this.current_month = Math.ceil(
						moment
							.duration(
								moment(this.current_date) - moment(this.start_date)
							)
							.as("months")
					);
				}
			}
			this.errors = errors;

			if (errors.length > 0) return false;
			else {
				if (this.no_plan && this.value.started_at) {
					this.total_date = moment().isAfter(moment(this.value.completed_at))
						? this.value.completed_at
						: moment().format("YYYY/MM/DD");
					this.total_months = Math.ceil(
						moment
							.duration(
								moment(this.total_date) - moment(this.value.started_at)
							)
							.as("months")
					);
					this.start_date = this.value.started_at
					this.current_date = this.total_date;
					this.current_month = Math.ceil(
						moment
							.duration(
								moment(this.current_date) - moment(this.value.started_at)
							)
							.as("months")
					);
				}
			}
		},
		abs(e) {
			return Math.abs(fix(e, 2))
		},
		calcPlanDuration(p, started_at, finished_at, now) {
			let origin = {}
			let versions_map = {}
			let versions = []
			let last = []
			if (Array.isArray(p.nodes)) {
				origin = {
					version: "origin",
					nodes: p.nodes.filter(v => v.version == 'origin')
				}
				origin.nodes.sort((a, b) =>
						moment(a.plan_started_at).isAfter(b.plan_started_at) ? 1 : -1
					);
				p.nodes.filter(v => v.version != 'origin').forEach(n => {
					if (n.version && versions_map[n.version]) {
						versions_map[n.version].nodes.push(n)
					} else {
						versions_map[n.version] = {
							version: n.version,
							nodes: [n]
						}
					}
				})

				origin.nodes.forEach((n, i) => {
					if (i == 0)
						n.started_at = p.started_at
					n.finished_at = moment(n.started_at).add(n.plan_duration, 'days').format()
					if (i != origin.nodes.length - 1)
						origin.nodes[i + 1].started_at = n.finished_at
				})

				versions = Object.values(versions_map)
				versions.sort((a, b) => moment(a.version, 'YYYY-MM').isAfter(b.version, 'YYYY-MM') ? 1 : -1)
				let tmp_origin = cloneDeep(origin.nodes)
				versions.forEach(v => {
					v.nodes.sort(
						(a, b) => moment(a.started_at).isAfter(b.started_at) ? 1 : -1
					)
					v.nodes.forEach((n, i) => {
						let origin_node = tmp_origin.find(t => t.id == n.version_to)
						if (origin_node) {
							origin_node.hr = n.hr
							if(n.hrplans && n.hrplans.length > 0)
								origin_node.hrplans = n.hrplans
							if (i == 0)
								origin_node.plan_started_at = p.started_at

							if (n.plan_duration)
								origin_node.plan_duration = n.plan_duration
							origin_node.version = n.version
						}
					})
					v.nodes = cloneDeep(tmp_origin)
					v.nodes.forEach((n, i) => {
						if (i == 0)
							n.started_at = p.started_at
						
						n.finished_at = moment(n.started_at).add(n.plan_duration, 'days').format()
						if (i != v.nodes.length - 1)
							v.nodes[i + 1].started_at = n.finished_at
					})
				})

				last = versions.length > 0? versions[versions.length - 1]:origin

				let origin_started_at = origin.nodes[0].started_at
				let origin_finished_at = origin.nodes[last.nodes.length - 1].finished_at
				let last_started_at = last.nodes[0].started_at
				let last_finished_at = last.nodes[last.nodes.length - 1].finished_at

				let total = {};
				let [origin_plan_started_at, origin_plan_finished_at] = UTIL.timeIntersection(started_at || origin_started_at,
					finished_at || origin_finished_at,
					origin_started_at,
					origin_finished_at)
			

				let [plan_started_at, plan_finished_at] = UTIL.timeIntersection(
					started_at || last_started_at,
					finished_at || last_finished_at,
					last_started_at,
					last_finished_at
				)
				let [actual_started_at, actual_finished_at] = UTIL.timeIntersection(
					started_at || last_started_at,
					finished_at || last_finished_at,
					last_started_at,
					now
				)

				if (!plan_started_at || !plan_finished_at || !actual_started_at || !actual_finished_at) {
					return {}
				}

				// 服务工期
				let whole_duration = time_duration(last_started_at, last_finished_at)
				total.plan_duration = fix(time_duration(plan_started_at, plan_finished_at),0)
				total.origin_plan_duration = time_duration(origin_plan_started_at, origin_plan_finished_at)

				total.actual_duration = fix(time_duration(actual_started_at, actual_finished_at),0)
				total.duration_percent = fix(percent(total.actual_duration, total.plan_duration))
				total.duration_offset = fix(total.plan_duration - total.origin_plan_duration, 2)
				total.duration_offset_percent = percent(Math.abs(total.duration_offset), total.plan_duration
				)
				// 施工工期
				// total.plan_op_duration = time_duration(p.operated_at, p.completed_at)
				// total.acutal_op_duration = time_int_duration(p.operated_at, p.completed_at, p.operated_at, now)

				// 产值
				let amount = p.amount
				total.plan_production = last.nodes.reduce((acc,item)=>{
					let duration = time_int_duration(plan_started_at,plan_finished_at,item.started_at,item.finished_at)
					let total_duration = item.plan_duration
				
					return acc + fix(item.percent * amount * duration / total_duration / 100, 2)
				},0)
			
				// 
				total.origin_plan_production = origin.nodes.reduce((acc,item)=>{
					let duration = time_int_duration(plan_started_at,plan_finished_at,item.started_at,item.finished_at)
					let total_duration = item.plan_duration
					return acc + fix(item.percent * amount * duration / total_duration / 100, 2)
				},0)
				
				total.actual_production = last.nodes.reduce((acc,item)=>{
					let duration = time_int_duration(actual_started_at,actual_finished_at,item.started_at,item.finished_at)
					let total_duration = item.plan_duration
					
					return acc + fix(item.percent * amount * duration / total_duration / 100, 2)
				},0)
				

				total.production_percent = percent(total.actual_production, total.plan_production)
				total.production_offset = fix(total.plan_production - total.origin_plan_production, 2)
				total.production_offset_percent = percent(Math.abs(total.production_offset), total.origin_plan_production)

				// 收费
				total.plan_bill = fix(p.amount / 10000, 2)
				let billplans = p.bill_plans

				let bills = []
				
				if(p.bills)
					bills = p.bills.filter(v => moment(v.billed_at).isBetween(moment(plan_started_at),moment(now)))
				let bill_amount = bills.reduce((acc, item) => acc + item.amount, 0)

				total.actual_bill = fix(bill_amount, 2)
				if (billplans && billplans.length > 0) {
					billplans = billplans.map((v, i, a) => {
						let d = { ...v }
						if (i == 0)
							d.started_at = actual_started_at
						else
							d.started_at = a[i - 1].finished_at || a[i - 1].plan_finished_at
						if (d.finished_at)
							d.finished = true
						d.finished_at = d.finished_at || d.plan_finished_at
						return d
					})
					total.plan_bill = billplans.filter(v=>v.finished_at && moment(v.finished_at).isBetween(moment(plan_started_at),moment(plan_finished_at))).reduce((acc, item) => {
						return acc + item.amount
					}, 0)

					total.actual_bill = bills.reduce((acc, item) => {
					 	return acc + (item.amount || 0)
					 }, 0)
					// total.actual_bill = billplans.filter(v => v.finished).reduce((acc, item) => {
					// 	return acc + (item.amount || 0))
					// }, 0)
				}


				total.bill_percent = percent(total.actual_bill, total.plan_bill)
				total.bill_offset = 0
				total.bill_offset_percent = 0

				// 人员
				let actual_hrv = 0
				let plan_hrv = 0
				let origin_plan_hrv = 0
				let origin_current_hrv = 0

				origin.nodes.forEach(n => {
					if (Array.isArray(n.hrplans)) {
						origin_plan_hrv += (n.hrplans.reduce((acc, item) => {
							return acc + ((item.factor || 0) * time_int_duration(origin_plan_started_at, origin_plan_finished_at, n.started_at, (n.finished_at || origin_plan_finished_at),'months') )
						}, 0))

						origin_current_hrv += (n.hrplans.reduce((acc, item) => {
							return acc + (item.factor || 0) * time_int_duration(actual_started_at, actual_finished_at, n.started_at, (n.finished_at || now),'months' )
						}, 0))
					

					}
				})

			
				last.nodes.forEach(n => {
					if (Array.isArray(n.hrplans)) {
						
						plan_hrv += (n.hrplans.reduce((acc, item) => {
							return acc + (item.factor || 0) 
						}, 0)) * time_unit(n.plan_duration)
						
					}
				})


				if (Array.isArray(p.hr)) {
					actual_hrv = p.hr.reduce((acc, item) => {
						return acc + (item.factor || 0) * time_int_duration(actual_started_at, actual_finished_at, item.started_at, (item.finished_at || now) ,'months')
					}, 0)
				}
				
			
				total.plan_hrv = fix(plan_hrv, 2)
				total.actual_hrv = fix(actual_hrv, 2)
				total.hrv_percent = percent(actual_hrv, plan_hrv)
				total.offset_hrv = fix((actual_hrv - origin_current_hrv), 2)
				total.offset_hrv_percent = percent(Math.abs(total.offset_hrv), origin_plan_hrv)


				// 建安工程量
				total.actual_bp = 0
				total.plan_bp = 0
				if (p.building_production) {
					total.plan_bp = fix(p.building_production)

					total.actual_bp = fix(p.bp.reduce((acc, item) => {
						return acc + item.amount * time_int_duration(actual_started_at, actual_finished_at, item.started_at || actual_started_at, item.finished_at) / time_duration(item.started_at || actual_started_at, item.finished_at)
					}, 0), 2)
					if (total.plan_bp) {
						total.bp_percent = percent(total.actual_bp, total.plan_bp)
					}
				}

				// 人均产值
				total.plan_hra_production = 0
				total.actual_hra_production = 0
				total.actual_hra_bill = 0
				total.actual_hra_bp = 0
				let origin_plan_hra_production = 0

				if (total.plan_hrv) {
					total.plan_hra_production = fix(total.plan_production  * 12 / plan_hrv, 2)
				}
				
				if (origin_plan_hrv) {
					total.origin_plan_hra_production = fix(total.origin_plan_production   * 12 / origin_plan_hrv, 2)
				}

				if (total.actual_duration != 0 && actual_hrv != 0) {
					total.actual_hra_production = fix(total.actual_production  * 12 / actual_hrv, 2)
					total.actual_hra_bill = fix(total.actual_bill  * 12 / actual_hrv, 2)
					total.actual_hra_bp = fix(total.actual_bp  / actual_hrv, 2)
				}


				total.plan_hra_production_offset = fix(total.plan_hra_production - total.origin_plan_hra_production, 2)

				total.plan_hra_percent = percent(Math.abs(total.plan_hra_production_offset), total.origin_plan_hra_production)



				total.last_version = last.version
				return { ...total }



			}

			return {}
		},
		calcDataDuration(p, started_at, finished_at, now) {
			let total = {};

			let [plan_started_at, plan_finished_at] = UTIL.timeIntersection(
				started_at,
				finished_at,
				p.started_at,
				p.completed_at
			)
			let [actual_started_at, actual_finished_at] = UTIL.timeIntersection(
				started_at,
				finished_at,
				p.started_at,
				now
			)


			// 服务工期
			let whole_duration = time_duration(p.started_at, p.completed_at)
			total.plan_duration = time_duration(plan_started_at, plan_finished_at)
			total.actual_duration = time_duration(actual_started_at, actual_finished_at)
			total.duration_percent = fix(percent(total.actual_duration, total.plan_duration))
			total.duration_offset = total.actual_duration - total.plan_duration
			total.duration_offset_percent = total.duration_offset / total.plan_duration

			// 施工工期
			total.plan_op_duration = time_duration(p.operated_at, p.completed_at)
			total.acutal_op_duration = time_int_duration(p.operated_at, p.completed_at, p.operated_at, now)

			// 产值
			let amount = p.amount
			total.plan_production = fix(p.amount * total.plan_duration / whole_duration, 2)
			total.actual_production = fix(total.actual_duration / whole_duration * amount, 2)
			total.production_percent = percent(total.actual_production, total.plan_production)
			total.production_offset = 0
			total.production_offset_percent = 0

			// 收费
			total.plan_bill = fix(p.amount / 10000, 2)
			let billplans = p.bill_plans
			let bills = []
			if(Array.isArray(p.bills))
				bills = p.bills.filter(v => moment(v.billed_at).isBetween(started_at, now.endOf('month')))
			let bill_amount = bills.reduce((acc, item) => acc + item.amount, 0)

			total.actual_bill = fix(bill_amount, 2)
			if (billplans && billplans.length > 0) {
				billplans = billplans.map((v, i, a) => {
					let d = { ...v }
					if (i == 0)
						d.started_at = p.started_at
					else
						d.started_at = a[i - 1].finished_at || a[i - 1].plan_finished_at
					if (d.finished_at)
						d.finished = true
					d.finished_at = d.finished_at || d.plan_finished_at
					return d
				})
				total.plan_bill = billplans.reduce((acc, item) => {
					return acc + item.amount * time_int_duration(plan_started_at, plan_finished_at, item.started_at, item.finished_at) / time_duration(item.started_at, item.finished_at)
				}, 0)

				total.actual_bill = billplans.filter(v => v.finished).reduce((acc, item) => {
					return acc + item.amount * time_int_duration(plan_started_at, plan_finished_at, item.started_at, item.finished_at) / time_duration(item.started_at, item.finished_at)
				}, 0)
			}


			total.bill_percent = percent(total.actual_bill, total.plan_bill)
			total.bill_offset = 0
			total.bill_offset_percent = 0

			// 人员
			let actual_hrv = 0
			if (Array.isArray(p.hr)) {
				actual_hrv = p.hr.reduce((acc, item) => {
					return acc + (item.factor || 0) * time_int_duration(actual_started_at, actual_finished_at, item.started_at, (item.finished_at || now,'months') )
				}, 0)
			}
			total.actual_hrv = actual_hrv

			// 建安工程量
			total.actual_bp = 0
			total.plan_bp = 0
			if (p.building_production) {
				total.plan_bp = fix(p.building_production)

				total.actual_bp = fix(p.bp.reduce((acc, item) => {
					return acc + item.amount * time_int_duration(actual_started_at, actual_finished_at, item.started_at, item.finished_at) / time_duration(item.started_at, item.finished_at)
				}, 0), 2)
				total.bp_percent = percent(total.actual_bp, total.plan_bp)
			}

			// 人均产值

			total.actual_hra_production = 0
			total.actual_hra_bill = 0
			total.actual_hra_bp = 0

			if (total.actual_duration != 0 && actual_hrv != 0) {
				total.actual_hra_production = fix(total.actual_production * 12 / actual_hrv, 2)
				total.actual_hra_bill = fix(bill_amount / actual_hrv, 2)
				total.actual_hra_bp = fix(total.actual_bp / actual_hrv, 2)
			}


			return { ...total }

		},
		calc() {
			this.loading = true;
			let now = moment(this.current_date).startOf('day');

			let p = this.value;
			if (this.no_plan) {
				// 计算整体数据
				this.total = this.calcDataDuration(p, p.started_at, p.completed_at, now.clone())


				// 年度
				let year = now.year()
				let startOfYear = moment(year, 'YYYY').startOf('year')
				let endOfYear = moment(year, "YYYY").endOf("year")
				this.yearData = this.calcDataDuration(p, startOfYear, endOfYear, now.clone())
				this.yearData.year = year

				// // 月度
				let month = now.format("YYYYMM")
				let startOfMonth = moment(month, 'YYYYMM').startOf('month')
				let endOfMonth = moment(month, "YYYYMM").endOf("month")
				this.monthData = this.calcDataDuration(p, startOfMonth, endOfMonth, now.clone())
				this.monthData.month = moment(month).month() + 1

			} else {
				// 计算整体数据
				let total = {};


				this.total = this.calcPlanDuration(p, null, null, now.clone())

				// 年度
				let year = now.year()
				let startOfYear = moment(year, 'YYYY').startOf('year')
				let endOfYear = moment(year, "YYYY").endOf("year")
				this.yearData = this.calcPlanDuration(p, startOfYear, endOfYear, now.clone())
			
				this.yearData.year = year

				// 月度
				let month = now.format("YYYYMM")
				let startOfMonth = moment(month, 'YYYYMM').startOf('month')
				let endOfMonth = moment(month, "YYYYMM").endOf("month")
				this.monthData = this.calcPlanDuration(p, startOfMonth, endOfMonth, now.clone())
				this.monthData.month = moment(month).month() + 1
			}

			this.loading = false;
		},
	},
};
</script>

<style lang="less">
.empher {
  font-size: 14px;
  font-weight: bold;
}
</style>