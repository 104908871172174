import { render, staticRenderFns } from "./UserSelectGrouped.vue?vue&type=template&id=5672c9a2"
import script from "./UserSelectGrouped.vue?vue&type=script&lang=js"
export * from "./UserSelectGrouped.vue?vue&type=script&lang=js"
import style0 from "./UserSelectGrouped.vue?vue&type=style&index=0&id=5672c9a2&prod&lang=less"
import style1 from "./UserSelectGrouped.vue?vue&type=style&index=1&id=5672c9a2&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports