
<style lang="less">
.u-group-name {
  font-weight: bold;
  color: #333;
  padding: 5px 10px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 2px;
}

.u-group-name:hover {
  background: #39f;
  color: #fff;
  i {
    color: #fff !important;
  }
}
.u-group-item {
  padding: 5px;
  white-space: nowrap;
  cursor: pointer;
  margin: 2px;
  .count {
    font-size: 8px;
    padding: 0 5px;
    background: #aaa;
    color: #fff;
    margin-left: 5px;
    font-family: system;
  }
}

.u-group-item:hover,
.u-group-item-selected {
  color: var(--primary) !important;
  background: var(--bg2);
  font-weight: bold;
  .count {
    background: rgb(15, 105, 183);
    color: rgb(247, 247, 247);
  }
}

.edit-icon {
  cursor: pointer;
}

.edit-icon:hover {
  color: #39f;
}

.ivu-dropdown-menu {
  > div {
    max-height: 800px !important;
    max-width: 800px !important;
  }
}

.cm-table{
  .ivu-table{
    font-family: system !important;
    .ivu-table-body::-webkit-scrollbar{
      width:7px;
      height:7px;
    }
  }
}
</style>
<template>
  <div class="hs-container cm-table" style="height:calc(100% - 15px);margin-top:10px;">
    <div class="flex-wrap flex-between" style="margin-bottom:10px;">
      <div class="flex-wrap split5">
        <Input search style="width:220px" v-model="searchText" clearable @on-enter="page=1;getData()" @on-clear="page=1;getData()" />
       
        <Dropdown trigger="click" transfer placement="bottom-start">
          <Button icon="ios-funnel" :type="Object.values(selected_filters).filter(v=>v).length>0?'success':'default'">筛选
            <Icon type="md-arrow-dropdown" /></Button>
          <DropdownMenu slot="list">
            <div style="padding:0 10px;max-width:700px;">
              <div class="flex-wrap">
                <div class="u-group-name">全部</div>
                <div class="u-group-item" @click.stop="selected_filters={};getData()">清除</div>
              </div>
              <template v-for="f in filters">
                <div :key="f.title" class="flex-wrap align-start">
                  <div class="u-group-name">{{f.title}}</div>
                  <div class="u-group-item" :class="{
                    'u-group-item-selected':inArray(f.values,selected_filters[f.key])
                  }" style="color:#3af" @click.stop="selected_filters[f.key] = f.values.map(v=>v.value);getData()">全选</div>
                  <div class="u-group-item" style="color:#3af" @click.stop="selected_filters[f.key] = [];getData()">清除</div>
                  <div class="flex-wrap" style="overflow:hidden;flex-wrap:wrap;align-content:flex-start">
                    <template v-for="item in f.values">
                      <div :key="item.key" class="u-group-item" :class="{
                      'u-group-item-selected':selected_filters[f.key] && selected_filters[f.key].includes(item.value)
                    }" @click.stop="handleSelectFilter(f.key,item.value)" :style="item.count > 0?'#333':'#aaa'">{{item.name}}</div>

                    </template>

                  </div>
                </div>
              </template>
            </div>
          </DropdownMenu>
        </Dropdown>
        <Button icon="md-search" type="primary" @click="page=1;getData()">搜索</Button>
        <Dropdown trigger="click" transfer placement="bottom-start" v-if="!selected_notice">
          <Button icon="md-list" :type="mappedTmpl?'info':'default'">
            {{mappedTmpl?mappedTmpl.name:'未选择模板'}}
            <Icon type="md-arrow-dropdown" />
          </Button>
          <DropdownMenu slot="list">
            <div style="max-width:700px;">
              <template v-for="t in tmpls">
                <div class="flex-wrap u-group-name" @click="selected_cols=[...t.fields];selected_filters={...t.filters||{}};page=1;localSaveTmpl();SaveState()" style="min-width:150px;cursor:pointer;font-weight:normal;" :key="t.name">
                  <BaseIcon :icon="t.icon || 'overview'" style="margin-right:5px" color="#aaa" /> {{t.name}}</div>
                <Divider style="margin:3px 0" v-if="t.name=='管理视图'" :key="'_div'+t.name"></Divider>
              </template>
            </div>
          </DropdownMenu>
        </Dropdown>
        <Button icon="md-settings" @click="handleEditTmpl" v-if="!selected_notice">编辑列</Button>
        <Button type="primary" v-if="!mappedTmpl && !selected_notice" @click="showModalTmpl=true">保存为自定义模板</Button>
        <Button type="error" icon="md-trash" v-if="mappedTmpl && my_tmpls.includes(mappedTmpl) && !selected_notice" @click="handleDeleteTmpl(mappedTmpl)">删除</Button>

       
      </div>
      <div class="flex-wrap split5">
        <Button icon="md-refresh" @click="getData(true)" type="success">刷新</Button><Button @click="exportExcel">导出 Excel</Button></div>
    </div>
    <div style="height:calc(100% - 45px);margin:5px;position:relative;">
      <BaseTable 
        ref="table" 
        style="font-family:PingFang-SC !important" 
        :columns="computedComlumns" 
        :data="filteredData" 
        :count="total.count" 
        border
        paged
        :sum="sum"
        :page-size="pagesize"
        :page="page"
        @event="handleTableEvent" 
        :option="{
          summary:true
        }"></BaseTable>
      <Spin fix v-if="loading">
        <div style="padding:20px;min-width:200px">
          
            <BaseLoading />
        </div>
      </Spin>
    </div>

    <Modal v-model="showModalCharger" title="修改负责人" width="300" footer-hide :mask-closable="false">
      <div style="padding:10px">
        <BaseUserSelectGrouped v-model="current.charger_id" editable :option="{users}" />
        <div class="flex-wrap flex-right split10" style="margin-top:10px">
          <Button type="primary" @click="SaveCharger">提交</Button>
          <Button @click="showModalCharger=false">取消</Button>
        </div>
      </div>
    </Modal>

    <Drawer width="1000" v-model="showModalEditTmpl" title="编辑数据列" footer-hide>
      <Row :gutter="10" style="height:100%;position:relative;">
        <Col :span="10" style="height:100%;position:relative;">
        <div>
          <Input search placeholder="搜索" v-model="edit_tmpl_search" style="width:calc(100% - 40px);margin-bottom:10px;" />
        </div>
        <div style="width:100%;height:calc(100% - 50px);overflow-y:auto;background:var(--bg3);padding:5px;">
        
          <template v-for="g in edit_groups">
            <div :key="'_group'+g.name">
              <div style="color:var(--subtext3)">{{g.name}}</div>
              <div>
                  <Draggable :value="g.items" :group="{
                      name:'edit_tmpls',
                      pull:'clone',
                      put:false
                    }"  
                     dataIdAttr="key"
                    :animation="500" 
                    :clone="handleCloneEditColumn">
                      <template v-for="col in g.items">
                        <div class="edit-column" v-if="col" :key="'_col'+col.key">
                          {{col.title}}<template v-if="col.desc">:<span style="margin-left:10px;color:var(--subtext3)">{{col.desc}}</span>
                          </template>
                          <BaseIcon icon="md-add" size="17" color="var(--primary)" style="float:right" @click="edit_tmpls.push(col.key)"/>
                        </div>
                      </template>
                  </Draggable>
              </div>
            </div>
          </template>
        </div>
        </Col>
        <Col :span="14"  style="height:100%;position:relative;">
        <div class="flex-wrap flex-between">
          <Button @click="edit_tmpls=[]">全部取消</Button>
          <Button type="primary" @click="handleApplyColumn">应用</Button>
        </div>
        <div style="width:100%;height:calc(100% - 80px);overflow-y:auto;background:var(--bg3);padding:5px;margin-top:10px;">
          <div class="flex-wrap flex-between edit-column">
            <div class="flex-wrap"><div class="flex-wrap flex-center" style="background:var(--bg1);padding:1px 3px;width:25px;color:var(--text1);">定</div> <div style="width:100px;margin-left:5px;">{{cols[0].title}}</div> <template v-if="cols[0].desc"><span style="color:var(--subtext3)">{{cols[0].desc}}</span></template> </div>
                <div>
                </div>
          </div>
          <Draggable v-model="edit_tmpls"  
            style="padding-bottom:20px"
          :group="{
            name:'edit_tmpls'
            }" :animation="500">
            <template v-for="(col,index) in edit_tmpls.map(v=>cols.find(t=>t.key == v)).filter(v=>v)">
              <div class="flex-wrap flex-between edit-column" :key="'_col'+col.key">
                <div class="flex-wrap"><div class="flex-wrap flex-center" style="background:var(--bg1);padding:1px 3px;width:25px;color:var(--text1);flex-shrink:0;">{{index+1}}</div> <div style="width:100px;margin-left:5px;flex-shrink:0;">{{col.title}}</div> <template v-if="col.desc"><div style="color:var(--subtext3)">{{col.desc}}</div></template> </div>
                <div>
                  <BaseIcon class="edit-close" icon="md-close" color="var(--subtext3)" @click="edit_tmpls.splice(index,1)" />
                </div>
              </div>
            </template>
            <div class="flex-wrap flex-center">
            <BaseAlert style="color:var(--primary);margin-top:5px;">可拖拽至此处</BaseAlert>
            </div>
          </Draggable>
        </div>
        </Col>
      </Row>
    </Drawer>

    <Modal v-model="showModalAssistants" title="修改协助人" width="300" footer-hide :mask-closable="false">
      <div style="padding:10px">

        <BaseUserSelectGrouped v-model="current.assistants" editable :option="{users,multiple:true}" />
        <div class="flex-wrap flex-right split10" style="margin-top:10px">
          <Button type="primary" @click="SaveAssistants">提交</Button>
          <Button @click="showModalAssistants=false">取消</Button>
        </div>
      </div>
    </Modal>

    <Modal v-model="showModalFocus" title="关注原因" width="400" footer-hide>
      <div class="flex-wrap split10" style="padding:10px">
        <BaseMark v-model="formData.focused" editable></BaseMark>
        <Input v-model="formData.focused_reason" style="width:calc(100% - 100px)" />
        <Button type="primary" @click="handlePatchFocused">提交</Button>
      </div>
    </Modal>

    <Modal v-model="showContract" title="查看合约内容" width="800" footer-hide>
      <div style="padding:10px">
        <template v-for="c in current.contracts">
          <div :key="c.id">
            <div style="font-weight:bold;margin:5px 0;">{{c.name}}</div>
            <div class="flex-wrap">
              <div class="u-icon" style="width:auto;padding:0 5px;font-size:10px;">{{c.contract_type}}</div>
              <div class="u-icon" style="width:auto;padding:0 5px;font-size:10px;">{{c.business_type}}</div>
            </div>
            <div style="padding:10px;background:#fff;margin-top:5px;">
              <template v-for="(t,i) in c.conditions.filter(v=>v.type == '收费节点')">
                <Row class="flex-wrap" style="margin-bottom:2px" :key="t.id">
                  <Col :span="1"><span class="u-icon">{{i+1}}</span></Col>
                  <Col :span="3"><span style="color:#39f;margin-right:10px;">{{t.type}}</span>
                  </Col>
                  <Col :span="14">{{t.detail}}</Col>
                  <Col :span="2" align="right">{{t.percent?t.percent.toFixed(2):'-'}} %</Col>
                  <Col :span="4" align="right">¥ {{formatSalarySemicolon(t.amount)}}</Col>
                </Row>
              </template>
              <Row style="border-top:1px solid #ddd;padding-top:5px;margin-top:5px;">
                <Col :span="24" align="right">合计：¥ {{formatSalarySemicolon(c.amount)}}</Col>
              </Row>
              <div style="margin:10px 0;padding:10px;background:#fff;" v-if="c.conditions.filter(v=>v.type == '特殊条款').length>0" />
              <template v-for="(t,i) in c.conditions.filter(v=>v.type == '特殊条款')">
                <div class="flex-wrap" style="margin-bottom:2px" :key="t.id"><span class="u-icon">{{i+1}}</span> <span style="color:#39f;margin-right:10px;">{{t.type}}</span> {{t.detail}}</div>
              </template>
            </div>
          </div>
        </template>

      </div>
    </Modal>

    <Modal v-model="showModalTmpl" title="保存为模板" footer-hide>
      <div class="flex-wrap split10" style="margin:10px;flex-wrap:nowrap;">
        <Input v-model="tmpl_name" auto-focus placeholder="输入模板名称" style="flex-shrink:1;flex-grow:1;width:auto;" />
        <Button type="primary" @click="handleSaveTmpl">提交</Button>
      </div>
    </Modal>

   

    <Modal v-model="showModalAdjust" title="一键调整" footer-hide width="300">
      <div style="padding:20px">
        <div style="margin-bottom:10px">项目：{{formData.name}}</div>
        <div class="flex-wrap flex-between">
          <Input v-model="formData.adjust_amount" number style="width:calc(100% - 80px)" />
          <Button type="primary" @click="handlePatchAdjustAmount">提交</Button>
        </div>
      </div>
    </Modal>

    <Drawer v-model="showEmployees" title="在岗人员" width="500">
      <div style="padding:20px">

      </div>
    </Drawer>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Draggable from 'vuedraggable'
import * as XLSX from 'xlsx'
import moment from 'moment'
import UTIL from '@/utils'
import CMR from './render'
import {cloneDeep} from 'lodash'
export default {
  data() {
    return {
      page:1,
      pagesize:50,
      order:{key:'id',type:'desc'},
      tmpl_name: "",
      showModalFocus: false,
      edit_tmpl_search: "",
      showModalEditTmpl: false,
      edit_tmpls: [],
      showContract: false,
      showModalTmpl: false,
      showEmployees:false,
      showModalDeps: false,
      showModalAssistants: false,
      projects:[],
      total:{},
      sum:{},
      loading:false,  
      showModalCharger: false,
      modified: null,
      current: {},
      selected_id: null,
      selected_notice: null,
      filter_values:[],
      searchText: "",
      status: ['准备中', '进行中', '已竣工', '已结束', '已中止'],
      states: ['待处理', '处理中', '已办结', '已关闭'],
      business_types: [
				"全过程咨询",
				"项目管理",
				"房建监理",
				"市政监理",
        "装修工程",
				"造价咨询",
				"BIM咨询",
				"招标代理",
				"对外合作",
				"其他",
			],
			business_type_colors: [
				"orange",
				"#369",
				"green",
				"darkred",
        "#388",
				"purple",
				"#3af",
				"#39f",
				"#953",
				"#333",
			],
      state_colors: ['var(--subtext3)', 'var(--primary)', 'var(--success)', 'var(--error)'],
      selected_cols: [],
      selected_filters: {
        status:["准备中","进行中"]
      },
      my_tmpls: [],
      showModalAdjust: false,
      formData: {}
    }
  },
  mounted() {
    if(this.projects.length == 0){
      setTimeout(() => {
        
        this.getTmpls()
        this.$store.dispatch("session/getUsers")
        this.$store.dispatch("session/getDeps")
        this.RestoreState()
        this.getData(true)
      }, 500)
    }

  },
    watch:{
    $route:{
      handler(v){
        let query =  this.$route.query.q
        if(query){
          let tmpl = this.tmpls.find(v=>v.name==query)
          if(tmpl){
           
            this.selected_cols = [...tmpl.fields]
            this.selected_filter = {status:["准备中","进行中"]} 
          }
        }
      },
      immediate:true,
      deep:true
    },
    my_role:{
      handler(){
        this.getData()
      },
      deep:true
    }
  },
  components: { Draggable },
  updated() {
    this.$nextTick(() => {
      if (this.$refs && this.$refs.table && this.$refs.table.calcTableHeight)
        this.$refs.table.calcTableHeight()
    })

  },
  computed: {
    ...mapGetters('session', ['session', 'users']),
    ...mapGetters('cm',['my_role']),
    deps(){
      return this.$store.getters['session/deps'].filter(v=>v.in_business)
    },
    mappedTmpl() {
      return (this.tmpls.find(v => [...v.fields].sort().join('-') == [...this.selected_cols].sort().join('-') && JSON.stringify(v.filters || {}) == JSON.stringify(this.selected_filters)))
    },
    tmpls() {
      return [{
        name: "项目总体报表",
        icon: "contract",
        fields: ['code', 'type', 'name','charger_id','status','total_production_percent','remain_amount','year_bill_plan_amount','year_billed_amount','year_plan_production','year_actual_production','year_plan_hrv','year_actual_hra_bill','year_hra_production','completed_at'],
        filters:{status:["准备中","进行中"]} 
      }, {
        name: "合同收费报表",
        icon: "money",
        fields:['code', 'type', 'name','charger_id','status','signed_amount','adjust_amount','amount','inbill_amount','reduct_amount','income_amount','billed_amount','billed_percent','transfer_percent','remain_amount','year_bill_plan_amount','year_billed_amount','year_remain_bill_amount','bill_plans','season_actual_bill','next_month_plan_bill_1','next_month_plan_bill_2','next_month_plan_bill_3','contractCount'],
        filters:{status:["准备中","进行中"]} 

      }, {
        name: "人员报表",
        icon: "supervisor",
        fields: ['code', 'type', 'name','charger_id','status','hr_count','total_plan_hrv','total_actual_hrv','total_predict_hrv','year_plan_hrv','year_actual_hrv','year_predict_hrv','hr_users'],
        filters:{status:["准备中","进行中"]} 
      }, {
        name: "项目进度报表",
        icon: "Building-",
        fields: ['code', 'type', 'name','charger_id','status','operated_at','completed_at','total_plan_duration','total_remain_duration','year_end_progress','total_plan_production','total_production_percent','year_plan_production','year_predict_production','year_hra_production','building_production','pb_percent','year_actual_bp','year_actual_hra_bp'],
        filters:{status:["准备中","进行中"]} 
      }, {
        name: "人均产值报表",
        icon: "user",
        fields: ['code', 'type', 'name','charger_id','status', ...this.cols.filter(v => v.group == '人均产值').map(v => v.key)],
        filters:{status:["准备中","进行中"]} 
      },  {
        name: "管理视图",
        icon: "md-create",
        fields:['code','type','name','status',...this.cols.filter(v => v.group == '管理').map(v => v.key)],

      }, ...this.my_tmpls]
    },
  
    filteredData() {
      return this.projects
    },
    filters() {
      var that = this
      let filterTypes  = [{
        title: "归属部门",
        key: "deps",
        values: [],
        array: true,

        formatter: v => (that.deps.find(t => t.id == v) || { name: (!v || v === undefined) ? "未分类" : v }).name
      }, {
        title: "项目类型",
        key: 'type',
        values: []
      }, {
        title: "项目状态",
        key: "status",
        values: [],
         sorter: (a, b) => {
          const items = ["准备中", "进行中", "已竣工", "已结束", "已关闭"]
          return items.findIndex(t => t == a.name) > items.findIndex(t => t === b.name) ? 1 : -1
        },
      }, {
        title: "签约时间",
        key: "signed_at",
        formatValue: e => moment(e).isValid() ? moment(e).year().toString() : '其他',
        values: []
      }, {
        title: "竣工时间",
        key: "completed_at",
        formatValue: e => moment(e).isValid() ? moment(e).year().toString() : '其他',
        values: []
      }, {
        title: "收费进度",
        key: "billed_percent",
        values:[],
        formatValue: e => {
          e = e*100
          if (e < 20) {
            return "20%以下"
          } else if (e < 50) {
            return "20%"
          } else if (e < 70) {
            return "50%"
          } else if (e < 100) {
            return "70%"
          } else {
            return "100%"
          }
        },
        sorter: (a, b) => {
          const items = ["20%以下", "20%", "50%", "70%", "100%"]
          return items.findIndex(t => t == a.name) > items.findIndex(t => t === b.name) ? 1 : -1
        },
        values: []
      },{
        title:"签约计划",
        key:"has_origin_plan",
        values: [],
        formatValue:e=>{
          if(e)
            return '有'
          else
            return '无'
        }
      }, {
        title: "项目规模",
        key: "amount",
        formatValue: e => {
          if (e < 1000000) {
            return "100万以下"
          } else if (e < 5000000) {
            return "100万"
          } else if (e < 10000000) {
            return "500万"
          } else if (e < 20000000) {
            return "1000万"
          } else {
            return "2000万以上"
          }
        },
        sorter: (a, b) => {
          const items = ["100万以下", "100万", "500万", "1000万", "2000万以上"]
          return items.findIndex(t => t == a.name) > items.findIndex(t => t === b.name) ? 1 : -1
        },
        values: []
      }]

      if(Array.isArray(this.filter_values)){
        this.filter_values.map(v=>{
          let type = filterTypes.find(t=>t.key == v.key)
          if(type)
            type.values = v.values
        })
      }

      if(this.my_role.extra)
        return filterTypes.slice(1)

      return filterTypes
    },
    cols() {
      var that = this
      return [{
        type: 'index',
        title: "序",
        key: "index",
        fixed:"left",
        width: 80,
        desc:"项目顺序号",
      }, {
        type: 'text',
        key: "code",
        title: "项目编码",
        desc:"项目编码",
        
        fixed:"left",
        width: 80,
        group: '项目',
        render:CMR.renderCode
      }, {
					type: "text",
					title: "业务类型",
          
        fixed:"left",
					key: "type",
					group: "项目",
					width: 100,
					option: {
						align: "center",
					},
					render: CMR.renderBusinessType(this.handleChangeBusinessType)
				}, {
        type: 'text',
        title: "项目名称",
        desc:'项目名称',
        fixed:"left",
        group: '项目',
        linkEvent: "open",
        fields:['shortname'],
        key: "name",
        width: 280,
        render:CMR.renderName(this.handleOpen),
      }, {
        type: 'number',
        title: "签约金额",
        desc:"合同签订时的金额",
        key: "signed_amount",
        width: 120,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),
          highlight:true,
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "合同金额",
        desc:"经过各类调整和附加合同之后计算总额",
        key: "amount",
        width: 120,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "调整金额",
        desc:"相对于签约金额的调整差额",
        key: "adjust_amount",
        group: '合约',
        width: 120,
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "拟开票总额",
        desc:"由我公司开票的总额",
        key: "inbill_amount",
        width: 120,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },{
        type: 'number',
        title: "扣除额",
        desc:"外委或对外合作扣除金额",
        key: "deduct_amount",
        width: 120,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },{
        type: 'number',
        title: "合同净额",
        desc:"合同额扣除了外委或对外合作后的净值",
        key: "income_amount",
        width: 120,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },{
        type:"number",
        title:"年底合同余额",
        key:"end_year_remain_contract_amount",
        width:140,
        group:"收费",
        option:{
          type:"fullAmount",
          formatter:e => UTIL.formatSalary(e),
          highlight:true,
          sumable: true,
        }
      },{
        type:"number",
        title:"总产值",
        key:"total_plan_production",
        width:140,
        group:"产值",
        option:{
          type:"fullAmount"
        }
      },{
        type:"number",
        title:"已完成产值比例",
        width:140,
        key:"total_production_percent",
        group:"产值",
        option:{
          type:"percent",
          percentToValue:100,
          formatted_value:true
        }
      },{
        type:"number",
        title:"年计划产值",
        key:"year_plan_production",
        width:140,
        group:"产值",
        option:{
          formatter:e => UTIL.formatSalary(e),
          highlight:true,
          sumable: true,
          
          fix:0,
          type:"fullAmount"
        }
      },{
        type:"number",
        title:"年实际产值",
        key:"year_actual_production",
        width:140,
        group:"产值",
        option:{
          formatter:e => UTIL.formatSalary(e),
          highlight:true,
          sumable: true,
          fix:0,
          type:"fullAmount"
        }
      },{
        type:"number",
        title:"年预测产值",
        key:"year_predict_production",
        width:140,
        group:"产值",
        option:{
          type:"fullAmount",
          formatter:e => UTIL.formatSalary(e),
          highlight:true,
          sumable: true,
        }
      },{
        type:"number",
        title:"年底剩余产值",
        key:"end_year_remain_production",
        width:140,
        group:"产值",
        option:{
          type:"fullAmount",
          formatter:e => UTIL.formatSalary(e),
          highlight:true,
          sumable: true,
        }
      },{
        type:"number",
        title:"年人均产值",
        key:"year_hra_production",
        width:140,
        group:"产值",
        option:{
          type:"fullAmount"
        }
      }, {
        type: "text",
        title: "状态",
        desc:"项目的进行状态",
        key: "status",
        group: "项目",
        width: 80,
        option: {
          align: "center",
        },
        render: (h, { row }) => {
          const status_colors = {
            "准备中": "#aaa",
            "进行中": "var(--primary)",
            "已竣工": "var(--active)",
            "已结束": "var(--success)",
            "已中止": "var(--error)"
          }
          let iconArrow = h("BaseIcon", { props: { icon: "ios-arrow-down" }, style: "margin-left:3px;" })
          let domContent = h(
            "div",
            { class: "flex-wrap flex-center", style: { height: "40px" } },
            [
              h(
                "div",
                {
                  style: {
                    width: "65px",
                    borderRadius: "50px",
                    textAlign: "center",
                    background: status_colors[row.status],
                    color: "#fff",
                  },
                },
                [row.status, iconArrow]
              ),
            ]
          );

          let domListItems = this.status.map((v, i) =>
            h(
              "DropdownItem",
              { props: { name: v, selected: row.status == v, value: v } },
              v
            )
          );
          let domList = h("DropdownMenu", { slot: "list" }, domListItems);
          let domDropDown = h(
            "Dropdown",
            {
              props: { trigger: "click", size: "small" },
              on: {
                "on-click": (status) => {
                  this.$api
                    .patch("/projects/" + row.id, { status })
                    .then((res) => {
                      let updateInfo = res.data.data;
                      updateInfo.status = status;
                      updateInfo.id = row.id;
                      this.updateItem(updateInfo);
                      this.$Notice.success({
                        title: "修改成功",
                        content: status,
                      });
                    });
                },
              },
            },
            [domContent, domList]
          );
          return domDropDown;
        },
      }, {
        key: "partA",
        group: "合约",
        title: "业主单位",
        desc:"合同的甲方单位",
        type: "text",
        width: 200
      }, {
        type: 'time',
        group: '合约',
        desc:"合同的中标日期",
        title: "中标日期",
        key: "bid_at",
        width: 120,
        option: {
          type: "date"
        }
      }, {
        type: 'time',
        group: '合约',
        title: "签约日期",
         desc:"合同的签约日期",
        key: "signed_at",
        width: 120,
        option: {
          type: "date"
        }
      }, {
        group: '合约',
        title: "合约条款",
        key: "conditions",
        width: 120,
        render(h, { row }) {
          return h('a', {
            style: "color:#39f;cursor:pointer;",
            on: {
              click() {
                that.showContract = true
                that.current = row
              }
            }
          }, `查看 (${row.conditions.length})`)
        }
      }, {
        type: 'time',
        group: '项目',
        title: "开始日期",
        key: "started_at",
        width: 120,
        option: {
          type: "date"
        }
      }, {
        type: 'time',
        title: "开工日期",
        group: "项目",
        key: "operated_at",
        width: 120,
        option: {
          type: "date"
        }
      }, {
        type: 'time',
        title: "竣工日期",
        group: "项目",
        key: "completed_at",
        width: 120,
        option: {
          type: "date"
        }
      }, {
        type: 'time',
        title: "结束日期",
        group: "项目",
        key: "finished_at",
        width: 120,
        option: {
          type: "date"
        }
      }, {
        type: 'text',
        group: "管理",
        title: "关联项目",
        key: "zzl_id",
        width:120,
        render:(h,{row})=>{
          if(row.zzl_id){
            let url = "http://zzlatm.gicp.net:10000/pip/index.html?pid="+row.zzl_id
            let icon = h('BaseIcon',{props:{icon:'ios-arrow-forward'},style:'margin-left:3px'})
            return h('Button',{
              style:"padding-right:0",
              props:{
                size:"small",
              }, 
            on:{
              click:()=>this.RouteTo(url,true)
            }},["已关联",icon])
          }else{
            return h('span','-')
          }

        }
      }, {
        group: "管理",
        title: "归属事业部",
        type: "text",
        key: "deps",
        width: 220,
        option:{
          formatter:(values)=>values.map(v=>{
            let dep = that.deps.find(g => g.id == v.group_id) || {
              name: v,
              error: true
            }
            let percent = (v.percent * 100) || (values.length ? parseInt(100 / values.length):0)
            dep.amount = v.amount
            return dep.name +'('+ (percent?percent.toFixed(0):0)+'%)'
          }).join(',')
        },
        render(h, { row }) {
          let groups = row.deps.map(v => {
            let dep = {...that.deps.find(g => g.id == v.group_id),percent:v.percent} || {
              name: v,
              percent:v.percent,
              error: true
            }
            return dep
          }
          
          )

          let domGroups = groups.map(v => v.error ? h('span', { style: "color:red;" }, v.name) : h('span', { style: "color:#39f;margin:0 5px;" }, [v.name.slice(0,2),(100 * v.percent).toFixed(0)+'%']))

          let domEdit = h("BaseIcon", {
            props: {
              icon: "md-create"
            },
            class: "edit-icon",
            on: {
              click() {
                that.EditDeps(row)
              }
            },
            style: {
              marginLeft: "5px"
            }          })
          return h("div",
            {              class: "flex-wrap flex-center", style: {
                flexWrap: "wrap"
              }            },
            [...domGroups])
        }
      },
      {
        key: "billed_amount",
        group: "收费",
        title: "开票金额",
        type: "number",
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },
      {
        key: "billed_percent",
        group: "收费",
        title: "开票占比",
        type: "number",
        option: {
          type: "percent"
        },
      },
      {
        key: "transfer_amount",
        group: "收费",
        title: "到账金额",
        type: "number",
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },
      {
        key: "transfer_percent",
        group: "收费",
        title: "到账占比",
        type: "number",
        option: {
          type: "percent"
        }
      },
      {
        key: "remain_amount",
        group: "收费",
        title: "合同余额",
        type: "number",
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },

      //　收费计划部分
      {
        key: "year_bill_plan_amount",
        group: "收费计划",
        title: "今年计划收款",
        type: "number",
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },
      {
        key: "year_billed_amount",
        group: "收费计划",
        title: "今年已开票",
        type: "number",
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },
      {
        key: "year_remain_bill_amount",
        group: "收费计划",
        title: "今年尚可开",
        type: "number",
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },
      {
        key: "bill_plans",
        group: "收费计划",
        title: "收费计划",
        width: 250,
        render(h, { row }) {
          if(!row.bill_plans || row.bill_plans.length == 0)
            return h("span","-")
          let doms = row.bill_plans.map((v, i) => h('div', { class: "flex-wrap split5", style: `padding:0 5px;${v.finished_at ? 'color:green;' : (moment(v.plan_finished_at).isBefore(moment()) ? 'color:red' : '')}` }, [i + 1, " ", UTIL.formatSalary(v.amount), " ", v.plan_finished_at ? moment(v.plan_finished_at).format("YYYY-MM-DD") : "无日期", " ", v.name]))
         
          let icon = h('BaseIcon',{props:{icon:'ios-eye'},style:"margin-left:3px"})
          let trigger = h('a',{style:"padding:2px 5px;background:var(--primary);color:var(--hover-text);border-radius:3px;"},[`查看(${doms.length})`])
          let content = h('div', { style: "text-align:left;" }, doms)
          let contentWrap = h("DropdownMenu",{slot:"list"},[content])
          return h("Dropdown",[trigger,contentWrap])
        }
      },
      {
        type:"season_actual_bill",
        title:"本季度已开票",
        group: "收费计划",
        width:140,
        option:{
          type:"fullAmount"
        }
      },
      {
        type:"number",
        key:"next_month_plan_bill_1",
        title:moment().add(1,'months').format("MM月计划开票"),
        group: "收费计划",
        width:140,
        option:{
          type:"fullAmount"
        }
      },
      {
        type:"number",
        key:"next_month_plan_bill_2",
        title:moment().add(2,'months').format("MM月计划开票"),
        group: "收费计划",
        width:140,
        option:{
          type:"fullAmount"
        }
      },
      {
         type:"number",
        key:"next_month_plan_bill_3",
        title:moment().add(3,'months').format("MM月计划开票"),
        group: "收费计划",
        width:140,
        option:{
          type:"fullAmount"
        }
      },
      
      {
        key: "origin_plan",
        group: "总控计划",
        title: "初始总控计划",
        width: 100,
        render(h, { row }) {
          if(!row.origin_plan)
            return h('span','-')
       
          let doms = row.origin_plan.nodes.map((v, i) => h('div', { class: "flex-wrap split5", style: `padding:0 5px;${v.finished_at ? 'color:green;' : (moment(v.started_at).isBefore(moment()) ? 'color:red' : '')}` }, [i + 1, " ",  (v.percent != undefined) ? (v.percent + '%') : '-', " ", v.started_at ? moment(v.started_at).format("YYYY-MM-DD") : "-", " ", v.name]))
          let content = h('div', { style: "text-align:left;" }, doms)
          let contentWrap = h("DropdownMenu",{slot:"list"},[content])
          let trigger = h('div',{style:'width:60px;border-radius:5px;cursor:pointer;padding:1px 0;background:var(--primary);color:var(--hover-text);'},`阶段(${row.origin_plan.nodes.length})`)
          return h('Dropdown', { style: "text-align:center;",props:{placement:'right'} }, [trigger,contentWrap])
        }
      },
      {
        key: "current_plan",
        group: "总控计划",
        title: "当前总控计划",
        width: 100,
        render(h, { row }) {
          if(!row.current_plan)
            return h('span','-')
          let doms = row.current_plan.nodes.map((v, i) => h('div', { class: "flex-wrap split5", style: `padding:0 5px;${v.finished_at ? 'color:green;' : (moment(v.started_at).isBefore(moment()) ? 'color:red' : '')}` }, [i + 1, " ", (v.percent != undefined) ? (v.percent + '%') : '-', " ", v.started_at ? moment(v.started_at).format("YYYY-MM-DD") : "-", " ", v.name]))
          let content = h('div', { style: "text-align:left;" }, doms)
          let contentWrap = h("DropdownMenu",{slot:"list"},[content])
          let trigger = h('div',{style:'width:60px;border-radius:5px;cursor:pointer;padding:1px 0;background:var(--primary);color:var(--hover-text);'},row.current_plan.version=='origin'?'初始':row.current_plan.version)
          return h('Dropdown', { style: "text-align:center;",props:{placement:'right'} }, [trigger,contentWrap])
        }
      },
      {
        key: "charger_id",
        group: "管理",
        type:'user',
        title: "负责人",
        width: 120,
        render(h, { row }) {
          let domEdit = h("BaseIcon", {
            props: {
              icon: "md-create"
            },
            class: "edit-icon",
            on: {
              click() {
                that.EditCharger(row)
              }
            },
            style: {
              marginLeft: "5px"
            }          })
          let domName = h("BaseNameBoard", {            props: {
              uid: row.charger_id
            }          })
          let domEmpty = h('span', { style: { color: "#aaa" } }, "未指定")
          return h('div', { class: 'flex-wrap flex-center', style: { padding: "0 10%" } }, [row.charger_id ? domName : domEmpty, domEdit])

        }
      }, {
        key: "assistants",
        group: "管理",
        title: "协助人",
        width: 120,
        render(h, { row }) {
          let domEdit = h("BaseIcon", {
            props: {
              icon: "md-create"
            },
            class: "edit-icon",
            on: {
              click() {
                that.EditAssistants(row)
              }
            },
            style: {
              marginLeft: "5px"
            }          })
          let domNames = h("BaseNameBoards", {            props: {
              uids: row.assistants, maxShow: 2
            }          })
          let domEmpty = h('span', { style: { color: "#aaa" } }, "未指定")
          return h('div', { class: 'flex-wrap flex-center', style: { padding: "0 10%" } }, [row.assistants && row.assistants.length > 0 ? domNames : domEmpty, domEdit])

        }
      }, {
        key: "buildingAddress",
        group: "项目详情",
        title: "项目地址",
        width: 200,
        type: "text"
      },
      {
        key: "buildingArea",
        group: "项目详情",
        title: "建筑面积",
        width: 120,
        type: "number"
      },
      {
        key: "buildingHeight",
        group: "项目详情",
        title: "建筑高度",
        width: 120,
        type: "number"
      },
      {
        key: "basementLevels",
        group: "项目详情",
        title: "地下室层数",
        width: 120,
        type: "number"
      },
      {
        key: "buildingCategory",
        group: "项目详情",
        title: "建筑类型",
        width: 200,
        type: "text"
      },
      {
        key: "buildingCount",
        group: "项目详情",
        title: "单体数量",
        width: 120,
        type: "number"
      },
      // ...
      {
        type: 'text',
        title: "当前阶段",
        key: "current_node",
        width: 120,
        group: '项目',
      },
      {
        type: 'text',
        title: "下一阶段",
        key: "next_node",
        width: 120,
        group: '项目',
      },
      {
        type: 'text',
        title: "签约计划",
        key: "origin_plan",
        width: 120,
        group: '项目',
        render:(h,{row})=>{
          if(row.origin_plan)
            return h('span','计划')
          else
            return h('span','-')
        }
      }, {
        type: 'text',
        title: "调整计划",
        key: "adjust_plan",
        width: 120,
        group: '项目',
      },
      {
        type: 'number',
        title: "建安工程量",
        key: "building_production",
        width: 120,
        group: '工程量',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "当前工程量",
        key: "current_bp",
        width: 120,
        group: '工程量',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      }, {
        key: "pb_percent",
        group: "工程量",
        title: "工程量占比",
        type: "number",
        option: {
          type: "percent"
        },
      },
      {
        key: "total_plan_hrv",
        group: "人力投入",
        width: 140,
        title: "总人力投入(计划)",
        type: "number",option:{
          
        formatter:v=>v ? v.toFixed(2) :0, 
        sumable:true,
        },
      },
      {
        key: "total_actual_hrv",
        group: "人力投入",
        width: 140,
        title: "总人力投入(实际)",
        sumable:true,
        type: "number",option:{
          
        formatter:v=>v ? v.toFixed(2) :0, 
        sumable:true,
        },
      },
      {
        key: "total_predict_hrv",
        group: "人力投入",
        width: 140,
        title: "总人力投入(预测)",
        type: "number",option:{
          
        formatter:v=>v ? v.toFixed(2) :0, 
        sumable:true,
        },
      },
      {
        key: "year_plan_hrv",
        group: "人力投入",
        width: 140,
        title: "年人力投入(计划)",
        type: "number",option:{
          
        formatter:v=>v ? v.toFixed(2) :0, 
        sumable:true,
        },
      },
      {
        key: "year_actual_hrv",
        group: "人力投入",
        width: 140,
        title: "年人力投入(实际)",
        type: "number",option:{
          
        formatter:v=>v ? v.toFixed(2) :0, 
        sumable:true,
        },
      },
      {
        key: "year_predict_hrv",
        group: "人力投入",
        width: 140,option:{
        formatter:v=>v ? v.toFixed(2) :0,        
        sumable:true,
        },
  
        title: "年人力投入(预测)",
        type: "number"
      },
      {
        key: "hr_count",
        group: "人力投入",
        width: 120,
        title: "在建人员数量",
        type: "number",
      },
      {
        key: "hr_users",
        group: "人力投入",
        width: 240,
        sortable:false,
        title: "在建人员名单",
        type: "text",
        formatter:users=>users.map(v=>{
          let user = this.users.find(t=>t.id == v.user_id)
          if(user){
            return user.name
          }
        }).filter(v=>v).join(' '),
        render:(h,{row})=>{
          if(Array.isArray(row.hr_users) && row.hr_users.length > 0)
            return h("div",{class:"flex-wrap",style:"flex-wrap:wrap"},row.hr_users.map(v=>h("BaseNameBoard",{props:{uid:v.user_id},style:"flex-shrink:0;padding-left:5px;margin:2px 5px;flex-grow:1;max-width:calc(50% - 5px);min-width:100px;"+(v.position=="总监" || v.position == "项目经理"?"border-left:2px solid var(--primary)":"")},[v.position])))
          else
            return h("span",'-')
        }
      },
      {
        key: "total_origin_plan_hra_production",
        group: "人均产值",
        width: 120,
        title: "总人均产值(计划)",
        type: "number",
        option:{
          type:"fullAmount",
          highlight:true
        }
      },
      {
        key: "total_plan_hra_production",
        group: "人均产值",
        width: 120,
        title: "总人均产值(预测)",
        type: "number",
        option:{
          type:"fullAmount",
          highlight:true
        }
      },
      {
        key: "total_actual_hra_production",
        group: "人均产值",
        width: 120,
        title: "总人均产值(实际)",
        type: "number",
        option:{
          type:"fullAmount",
          highlight:true
        }
      },
      {
        key: "total_actual_hra_bill",
        group: "人均产值",
        width: 120,
        title: "总人均开票(实际)",
        type: "number",
        option:{
          type:"fullAmount",
          highlight:true
        }
      },
      {
        key: "year_origin_plan_hra_production",
        group: "人均产值",
        width: 120,
        title: "年人均产值(计划)",
        type: "number",
        option:{
          type:"fullAmount",
          highlight:true
        }
      },
      {
        key: "year_plan_hra_production",
        group: "人均产值",
        width: 120,
        title: "年人均产值(预测)",
        type: "number",
        option:{
          type:"fullAmount",
          highlight:true
        }
      },
      {
        key: "year_actual_hra_bill",
        group: "人均产值",
        width: 120,
        title: "年人均开票(实际)",
        type: "number",
        option:{
          type:"fullAmount",
          highlight:true
        }
      },
      {
        key: "error",
        group: "人均产值",
        width: 200,
        title: "计算错误",
        type: "text"
      },
      {
        key: "total_actual_hra_bp",
        group: "工程量",
        width: 120,
        title: "总人均工程量",
        type: "number",
        option:{
          type:"fullAmount"
        }
      },
      {
        key: "year_actual_hra_bp",
        group: "工程量",
        width: 120,
        title: "年度人均工程量",
        type: "number",
        option:{
          type:"fullAmount"
        }
      },
      {
        key:"total_plan_duration",
        width:120,
        
        type:"number",
        group:"进度",
        title:"总工期(月)",
        option:{
          formatter:e=>parseInt(e*10/30.4)/10
        }
      },
      {
        key:"total_remain_duration",
        width:120,
        type:"number",
        group:"进度",
        title:"后续工期(月)",
        option:{
          formatter:e=>parseInt(e*10/30.4)/10
        }
      },
       {
        key:"year_end_progress",
        type:"text",
        width:120,
        group:"进度",
        title:"年末形象进度"
      },
      {
        key: "focused_reason",
        group: "管理",
        title: "关注原因",
        width: 300,
        render: (h, { row }) => {
          let mark = h('BaseMark', {
            style: `display:inline;margin-right:5px;`,
            props: {
              value: row.focused
            }
          })
          let edit = h('Icon', {
            props: {
              type: "md-create"
            },
            style: {
              style: "margin-left:5px;cursor:pointer;"
            },
            on: {
              click: () => {
                this.showModalFocus = true
                this.formData = {
                  id: row.id,
                  focused: row.focused,
                  focused_reason: row.focused_reason
                }
              }
            }
          })

          return h('div', { class: "split10", style: `padding:0 10px;text-align:left;color:${!row.focused_reason?'var(--subtext2)':'var(--text2)'}` }, [mark, row.focused_reason || '请输入关注原因', edit])
        }
      }, {
        key: "updated_at",
        title: "最近更新",
        group: "管理",
        width: 80,
        type: 'time'
      }, {
        key: "updated_by",
        title: "操作人员",
        group: "管理",
        width: 120,
        type: "user",
        option: {
          getters: "session/users"
        }
      }, {
        key: "mod_adjust",
        title: "快速调整",
        group: "管理",
        width: 120,
        render: (h, { row }) => {
          let amount = row.amount
          let adjust_amount = row.adjust_amount
          let signed_amount = row.signed_amount
          let billed_amount = row.billed_amount
          if (billed_amount > amount) {
            let icon = h('BaseIcon', {              props: { icon: "md-create" }, style: {
                marginLeft: "5px"
              }            })
            return h('a', {
              on: {
                click: () => {
                  this.formData = {
                    id: row.id,
                    name: row.name,
                    signed_amount: row.signed_amount,
                    adjust_amount: billed_amount - signed_amount
                  }
                  this.showModalAdjust = true
                }
              }
            }, ['一键调整', icon])
          } else {
            return h('span', { style: "color:#aaa" }, '无需调整')
          }

        }
      }]
    },
    computedComlumns() {
      let cols = [this.cols[0]]
      cols = cols.concat(this.selected_cols.map(key => this.cols.find(t => t.key == key)))

      return cols.filter(v=>v)
    },
    edit_groups() {
      let g = []
      this.cols.forEach(c => {
        if (!c.group || this.edit_tmpl_search && (!c.title || !c.title.includes(this.edit_tmpl_search)) || this.edit_tmpls.includes(c.key))
          return
        let group = g.find(v => v.name == c.group)
        if (group)
          group.items.push(c)
        else
          g.push({
            name: c.group,
            items: [c]
          })
      })

      return g
    },
    groups() {
      let g = []
      this.cols.forEach(c => {
        if (!c.group)
          return
        let group = g.find(v => v.name == c.group)
        if (group)
          group.items.push(c)
        else
          g.push({
            name: c.group,
            items: [c]
          })
      })

      return g
    }
  },
  methods: {
    formatSalarySemicolon(t) {
      return UTIL.formatSalarySemicolon(t)
    },
    localSaveTmpl() {
      let data = {
        selected_cols: this.selected_cols,
        selected_filters: this.selected_filters
      }
      localStorage.setItem('local_cm_tmpl' + this.session.id, JSON.stringify(data))

    },
    localLoadTmpl() {
      try {
        let { selected_cols, selected_filters } = localStorage.getItem('local_cm_tmpl' + this.session.id)
        if (selected_cols)
          this.selected_cols = selected_cols.filter(v=>this.cols.find(c=>c.key == v))
        else
          this.selected_cols = [...this.tmpls[0].fields]
        if (selected_filters)
          this.selected_filters = selected_filters
      } catch (e) {
        // just catch 
      }


    },
    handleChangeBusinessType(type){
      this.$api
        .patch("/projects/" + row.id, { type,business_type:type })
        .then((res) => {
          let updateInfo = res.data.data;
          updateInfo.type = type;
          updateInfo.id = row.id;
          this.updateItem(updateInfo);
          this.$Notice.success({
            title: "修改成功",
            content: type,
          });
        });
    },
    handleSaveTmpl() {
      if (!this.tmpl_name) {
        this.Error("清输入名称")
        return
      } else if (this.tmpls.find(v => v.name == this.tmpl_name)) {
        this.Error("此名称已存在")
        return
      }

      let tmpl = {
        name: this.tmpl_name,
        fields: this.selected_cols,
        filters: this.selected_filters
      }
      this.my_tmpls.push(tmpl)

      this.$api.post(`users/${this.session.id}/oa_tmpls`, this.my_tmpls).then(res => {
        this.$Notice.success({ title: "保存成功" })
        this.showModalTmpl = false

        this.tmpl_name = ""
      }).catch(e => {
        this.Error(e)
      })
    },
    getTmpls() {
      this.$api.get(`users/${this.session.id}/oa_tmpls`).then(res => {
        let tmpls = Array.isArray(res.data.data)?res.data.data:[]
        tmpls.forEach(t=>{
          t.fields = t.fields.filter(v=>this.cols.find(c=>c.key == v))
        })
        this.my_tmpls = tmpls
        this.localLoadTmpl()
      })
    },
    fix() {
      if (this.$refs && this.$refs.table)
        this.$refs.table.calcTableHeight()
    },
    exportExcel() {
      const FixData = v => {
        let res = []
        this.computedComlumns.filter(v => !v.unexport).forEach(c => {
          if (c.type != 'number' && (c.formatter || c.option && c.option.formatter)) {
            let formatter = c.formatter || c.option.formatter
            res.push(formatter(v[c.key]))
            return
          }

          if (c.type == 'state') {
            if (c.option && c.option.states) {
              res.push(c.option.states[v[c.key]])
            }
          }else if (c.type == 'number' && c.option && c.option.type == 'percent') {
            if(c.option.percentToValue){
              res.push(parseInt(v[c.key] * 100) / 100 + '%')
            }else{
              
              res.push(parseInt(v[c.key] * 10000) / 100 + '%')
            }
          } else if(c.type == 'number'){
            res.push(v[c.key] ? v[c.key]:0)
          } else if (c.type == 'time') {
            if (v[c.key] && !v[c.key].includes('1900') && !v[c.key].includes('0000'))
              res.push(moment(v[c.key]).format('YYYY/MM/DD'))
            else
              res.push("")
          }else if(c.type == 'user'){
            let id = v[c.key]
            res.push((this.$store.getters['session/users'].find(v=>v.id == id) || {name:id}).name)
          } else {
            res.push(v[c.key])
          }
        })

        return res
      }

      this.exporting = true
      this.getData(false,(projects,sum)=>{
        let data = projects.map((v, i) => {
          v.index = i + 1

          return FixData(v)
        })
        let date = moment().format("YYYYMMDD")
        const filename = `合约明细表${date}.xlsx`;

        const workbook = XLSX.utils.book_new()

        let Caption = [filename]
        let title = this.computedComlumns.filter(v => !v.unexport).map(v => v.title)

        let worksheet = XLSX.utils.aoa_to_sheet([Caption, title, ...data])


        XLSX.utils.book_append_sheet(workbook, worksheet, '明细表');

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });
        // save file
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        setTimeout(function () {
          // 延时释放掉obj
          URL.revokeObjectURL(link.href);
          link.remove();
        }, 500);
      },()=>{
        this.exporting = false
      })

      

    },
    updateItem(item) {
      this.$store.commit('cm/update_project', item)
    },
    handleDeleteTmpl(tmpl) {
      let index = this.my_tmpls.findIndex(v => v == tmpl)
      if (index == -1)
        return
      this.Confirm("删除此模板?", e => {
        this.my_tmpls.splice(index, 1)
        this.$api.post(`users/${this.session.id}/oa_tmpls`, this.my_tmpls).then(res => {
          this.$Notice.success({ title: "删除成功" })
          this.showModalTmpl = false

          this.tmpl_name = ""
        }).catch(e => {
          this.Error(e)
        })
      })
    },
    selectNotice(t) {

    },
    handlePatchFocused() {
      this.$api.patch(`projects/${this.formData.id}`, {
        focused: this.formData.focused,
        focused_reason: this.formData.focused_reason
      }).then(res => {
        this.showModalFocus = false

        let updateInfo = {
          id: this.formData.id,
          focused: this.formData.focused,
          focused_reason: this.formData.focused_reason
        }

        this.$store.commit('cm/update_project', updateInfo)
        this.formData = {}
        this.$Notice.success({ title: "修改成功" })
      })
    },
    handlePatchAdjustAmount() {
      this.$api.patch(`projects/${this.formData.id}`, {
        adjust_amount: this.formData.adjust_amount
      }).then(res => {
        this.showModalAdjust = false

        let updateInfo = {
          id: this.formData.id,
          adjust_amount: this.formData.adjust_amount,
          amount: this.formData.signed_amount + this.formData.adjust_amount
        }

        this.$store.commit('cm/update_project', updateInfo)
        this.formData = {}
        this.$Notice.success({ title: "修改成功" })
      })
    },
    handleSelectFilter(f, v) {
      if (Array.isArray(this.selected_filters[f])) {
        let index = this.selected_filters[f].findIndex(t => t == v)
        if (index != -1)
          this.selected_filters[f].splice(index, 1)
        else
          this.selected_filters[f].push(v)
      } else
        this.$set(this.selected_filters, f, [v])
      this.SaveState()
    },
    handleApplyColumn() {
      this.selected_cols = [...this.edit_tmpls]
      this.showModalEditTmpl = false
      this.SaveState()
    },
    inArray(array, sub) {
      if (Array.isArray(array) && Array.isArray(sub) && array.length > 0 && sub.length > 0 && sub.length <= array.length) {
        for (let i = 0; i < sub.length; i++)
          if (!array.includes(sub[i]))
            return
        return true
      }
    },
    outArray(array, sub) {
      if (Array.isArray(array) && Array.isArray(sub) && array.length > 0 && sub.length > 0) {
        for (let i = 0; i < sub.length; i++)
          if (array.includes(sub[i]))
            return false
        return true
      }
    },

    handleClearGroup(g) {
      if (!g)
        this.selected_cols = []
      else
        this.selected_cols = this.selected_cols.filter(v => !g.items.map(t => t.key).includes(v))
      this.SaveState()
    },
    handleSelectAllGroup(g) {
      g.items.forEach(v => {
        if (!this.selected_cols.includes(v.key))
          this.selected_cols.push(v.key)
      })
      this.SaveState()
    },
    handleSelectColumn(key) {
      let index = this.selected_cols.findIndex(v => v == key)
      if (index == -1)
        this.selected_cols.push(key)
      else
        this.selected_cols.splice(index, 1)
      this.SaveState()
    },
    handleOpen(p){
       let baseurl = '/core/projects/' + p.id
        this.$store.commit("session/push_project_cm", p)
        this.RouteTo(baseurl + '/cm')
    },
    handleTableEvent(e){
        if(e.type == 'page-change'){
          this.page = e.data
          this.getData()
        }else if(e.type == 'edit-name'){
          this.model = e.data
          this.formData = {shortname:e.data.shortname || ''}
          this.showShortNameEditor = true
        }else if(e.type == 'page-size-change'){
          this.pagesize = e.data
          this.getData()
        }else if(e.type == 'sort'){
          if(e.data && e.data.key){
            
            this.order = e.data
            this.page = 1
            this.getData()
          }else{
            this.order = {}
            this.getData()
          }
        }
      },
    EditCharger(item) {
      this.current = {
        id: item.id,
        charger_id: item.charger_id
      }
      this.showModalCharger = true
    },
    EditAssistants(item) {
      this.current = {
        id: item.id,
        assistants: item.assistants
      }
      this.showModalAssistants = true
    },
    RestoreState() {
      let query =  this.$route.query.q
      if(query){
        let tmpl = this.tmpls.find(v=>v.name==query)
        if(tmpl){
           this.selected_cols = [...tmpl.fields]
           this.selected_filters = {...tmpl.filters}
           return
        }
      }

      let state = localStorage.getItem("OA_PROJECTS_STATE_" + this.session.id)
      if (state) {
        state = JSON.parse(state)
        this.selected_cols = state.selected_cols
        this.selected_filters = state.selected_filters
        this.selected_id = state.selected_id
        this.selected_Text = state.selected_Text
        this.searchText = state.searchText
      }
        
      if(this.selected_cols.length < 2)
        this.selected_cols = ['code', 'type', 'amount', 'signed_at', 'status', 'charger_id', 'deps', 'adjust_amount', 'partA', 'contractCount', 'updated_at', 'updated_by']
    },
    SaveState(withoutGetData) {
      let state = {
        selected_id: this.selected_id,
        selected_notice: this.selected_notice,
        selected_Text: this.selected_Text,
        search_Text:this.searchText,
        selected_cols: this.selected_cols,
        selected_filters: this.selected_filters,
      }
      if(!withoutGetData)
        this.getData()
      localStorage.setItem("OA_PROJECTS_STATE_" + this.session.id, JSON.stringify(state))
    },
    SaveCharger() {
      let item = this.current
      this.$api.patch('projects/' + item.id, { charger_id: item.charger_id }).then(res => {
        this.$Notice.success({
          title: "修改成功"
        })
        let updateInfo = res.data.data
        updateInfo.id = item.id
        updateInfo.charger_id = item.charger_id
        this.$store.commit('cm/update_project', updateInfo)
        this.showModalCharger = false
        this.current = {}
      })
    },
    handleCloneEditColumn(e){
      return e.key
    },
    handleEditTmpl() {
      this.edit_tmpls = [...this.selected_cols]
      this.showModalEditTmpl = true
    },
    SaveAssistants() {
      let item = this.current
      this.$api.patch('projects/' + item.id, { assistants: item.assistants }).then(res => {
        this.$Notice.success({
          title: "修改成功"
        })
        let updateInfo = res.data.data
        updateInfo.id = item.id
        updateInfo.assistants = item.assistants
        this.$store.commit('cm/update_project', updateInfo)
        this.showModalAssistants = false
        this.current = {}
      })
    },
    EditDeps(item) {
      this.showModalDeps = true
      this.current = {
        id: item.id,
        deps: cloneDeep(item.deps)
      }
    },
    SaveDeps() {
      let item = this.current
      let deps = item.deps
      this.$api.patch('projects/' + item.id, { deps }).then(res => {
        this.$Notice.success({
          title: "修改成功"
        })
        let updateInfo = res.data.data
        Object.assign(item, updateInfo)
        this.$store.commit('cm/update_project', item)
        this.showModalDeps = false
        this.current = {}
      })
    },
    getData(forced,callback_then,callback_finally) {
      if(this.getDataTimer)
        clearTimeout(this.getDataTimer)
      let fields = [...this.selected_cols]
      this.selected_cols.forEach(v=>{
        let col = this.cols.find(c=>c.key == v)
        if(col && Array.isArray(col.fields))
          fields = fields.concat(col.fields)
      })

      this.getDataTimer = setTimeout(()=>{
        this.loading = true
        this.$api.post('cm/projects/list',{
          forced,
          page:callback_then ?1:this.page,
          search:this.searchText,
          pagesize:callback_then ? this.total.count:this.pagesize,
          order:this.order,
          filter:this.selected_filters,
          role:this.my_role.id,
          fields,
          sum:this.cols.filter(v=>this.selected_cols.includes(v.key) && v.option && v.option.sumable).map(v=>v.key)
        }).then(res=>{
       


          let result = res.data.data
          if(callback_then){
            callback_then(result.items,result.sum)
            return
          }
          this.projects = result.items
          this.total = result.total
          this.sum = result.total.sum
          this.filter_values = result.total.filter_values


          this.SaveState(true)

        }).catch(e=>{
          this.Error(e)
        }).finally(()=>{
          setTimeout(()=>{
            if(callback_finally)
              callback_finally()
            this.loading = false
          },200)
        })
      },500)
      
    },
  }
}
</script>
<style lang="less">
.edit-column {
  padding: 2px 5px;
  background: var(--bg2);
  margin: 2px;
  border-left: 5px solid var(--border);
}

.edit-column:hover {
  border-color: var(--primary);
  cursor: pointer;
}

.edit-close:hover {
  color: var(--primary) !important;
}
</style>
